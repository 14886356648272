import React from 'react'
import { Row, Button, Modal, ModalBody } from 'reactstrap';

export default function Hint(props) {
    return (

        <Modal isOpen={props.visible} className="top-50" autoFocus={true}>
            <ModalBody>
                <Row>
                    <h2 className="title-upgrade" style={{ color: '#93aad6' }}>{props.title}</h2>
                    <div className="col-12 text-center">
                        <img src={require(`../../../../assets/assets_ari/${props.img}`)} alt="" width="140px" />
                    </div>
                    <div className="col-12 text-center">
                        <p>{props.desc}</p>
                    </div>
                    <div className="col-12 text-center">

                        {props.onBack ?
                            <Row>
                                <div className="col-6 text-right">
                                    <Button type="submit" color="netis-color" onClick={props.onBack}>
                                        Kembali
                        </Button>
                                </div>
                                <div className="col-6 text-left">
                                    <Button type="submit" color="netis-color" onClick={props.onClick}>
                                        {props.actionTitle}
                                    </Button>
                                </div>
                            </Row>
                            :
                            <Row>
                                <div className="col-12 text-center">
                                    <Button type="submit" color="netis-color" onClick={props.onClick}>
                                        {props.actionTitle}
                                    </Button>
                                </div>
                            </Row>
                        }

                    </div>
                </Row>
            </ModalBody>
        </Modal>

    )
}