import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse, Navbar, 
    // NavbarToggler, 
    NavbarBrand, Nav, NavItem, 
    // Modal 
} from 'reactstrap';

import Logo from '../../assets/assets_ari/logo.png';

class NavbarComp extends Component {
    state = {
        collapsed: true,
        openDrawer: false
    }

    toggleNavbar = () => this.setState({
        openDrawer: true
    })

    closeDrawer = () => this.setState({
        openDrawer: false
    })
    redirectLink = (link) => (e) => {
        e.preventDefault()
        window.location.replace(link);
    }
    handleAboutClick = (e) => {
        e.preventDefault()
        if (document.getElementById('about')) {
            window.scrollTo({
                top: Number(document.getElementById('about').offsetTop) - 80,
                left: 0,
                behavior: 'smooth'
            })
            this.closeDrawer()
        } else {
            this.props.history.push('/');
        }
    }
    windowOnScroll () {
        if (window.scrollY > 0) {
            if (!document.getElementsByTagName('nav')[0].classList.contains('shadow-sm')) {
                document.getElementsByTagName('nav')[0].classList.add('shadow-sm')
            }
        } else {
            if (document.getElementsByTagName('nav')[0].classList.contains('shadow-sm')) {
                document.getElementsByTagName('nav')[0].classList.remove('shadow-sm')
            }
        }
    }

    componentDidMount() {
        window.addEventListener("scroll", this.windowOnScroll)
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.windowOnScroll)
    }
    render() {
        // const isActive = (link) => this.props.match.path === link;
        // const getNavItemClass = (link) => isActive(link) ? 'mr-3 active' : 'mr-3';
        return (
            <Fragment>
                <Navbar color="white" className="navbar-expand-md fixed-top custom-nav" light>
                    <div className="container">
                        <NavbarBrand href="/" className="mr-auto">
                            <img src={Logo} alt="widya-skilloka" className="navbar-logo" />
                        </NavbarBrand>
                        <Link className="d-md-none btn btn-sm btn-netis-primary px-2" to="/login">Login</Link>

                        {/* hide burger */}
                        {/* <NavbarToggler onClick={this.toggleNavbar} className="" /> */}
                        <Collapse isOpen={!this.state.collapsed} navbar>
                            <Nav navbar className="ml-auto">
                                {/* <NavItem className={getNavItemClass('/')}>
                                    <Link className="nav-link" to="/">Beranda</Link>
                                </NavItem>
                                <NavItem className={getNavItemClass('/about')}>
                                    <Link className="nav-link" to="/about">Tentang Kami</Link>
                                </NavItem>
                                <NavItem className={getNavItemClass('/services')}>
                                    <Link className="nav-link" to="/services">Layanan</Link>
                                </NavItem>
                                <NavItem className={getNavItemClass('/product-hris') + ' has-subitem'}>
                                    <Link className="nav-link" to="#">Produk</Link>
                                    <div className="nav-subitem pt-3">
                                        <div className="card-subitem px-0 py-2 card card-body shadow rounded-12 border-netis-secondary mb-0" style={{ border: 'solid 0px', borderTop: 'solid 3px' }}>
                                            <Link to="/product-hris" className="px-3 py-2">
                                                HRIS
                                            </Link>
                                            <a href="https://skillana.id" target="_blank" rel="noopener noreferrer" className="px-3 py-2">
                                                Career Passport
                                            </a>
                                        </div>
                                    </div>
                                </NavItem>
                                <NavItem className={getNavItemClass('/careers')}>
                                    <Link className="nav-link" to="/careers">Karir</Link>
                                </NavItem>
                                <NavItem className={getNavItemClass('/contact')}>
                                    <Link className="nav-link" to="/contact">Hubungi Kami</Link>
                                </NavItem> */}
                                <NavItem className="has-subitem">
                                    <Link className="btn btn-netis-primary px-4" to="/login">Login</Link>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </div >
                </Navbar >

                {/* <Modal isOpen={this.state.openDrawer} toggle={this.closeDrawer} className={'modal-drawer'} >
                    <div className="drawer container">
                        <div className="drawer-header pb-3">
                            <NavbarBrand href="/" className="mr-auto">
                                <img src={Logo} alt="widya-skilloka" className="navbar-logo" />
                            </NavbarBrand>
                            <NavbarToggler onClick={this.closeDrawer} className="close-drawer ml-auto" />
                        </div>
                        <div className="text-center d-flex flex-column justify-content-center">
                            <ul>
                            <li className="nav-item">
                                    <Link to="/" className="nav-link m-0 py-3">Beranda</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/about" className="nav-link m-0 py-3">Tentang Kami</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/services" className="nav-link m-0 py-3">Layanan</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="#" id="productCollapseMobileNav" className="nav-link m-0 py-3">Produk <i className="fa fa-caret-down"></i></Link>
                                    <UncontrolledCollapse toggler="#productCollapseMobileNav">
                                        <ul>
                                            <li className="nav-item">
                                                <Link to="/product-hris">HRIS</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="https://skillana.id">Career Passport</Link>
                                            </li>
                                        </ul>
                                    </UncontrolledCollapse>

                                </li>
                                <li className="nav-item">
                                    <Link to="/careers" className="nav-link m-0 py-3">Karir</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/contact" className="nav-link m-0 py-3">Hubungi Kami</Link>
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-center" onClick={this.redirectLink('/login')}>
                                <button className="btn btn-block btn-netis-primary py-3">Login</button>
                            </ul>
                        </div>
                    </div>
                </Modal> */}
            </Fragment>
        )
    }
}

export default withRouter(NavbarComp);
