import { apiRequest } from "../actions/api";
import { LOGIN, GET_ME, setUser, LOGOUT } from "../actions/auth";
import request from "../utils/request";
import * as firebase from '../firebaseInit';
import { cache } from 'swr';
import * as Sentry from '@sentry/react';

export const appMiddleware = ({ dispatch }) => next => action => {
    next(action);
    switch (action.type) {
        case LOGOUT: {
            console.log('LOGOUT!')
            firebase.unsubscribe();
            cache.clear();
            break;
        }
        case LOGIN: {
            next(
                apiRequest({
                    url: `auth/login`,
                    data: action.payload
                })
            );
            break;
        }
        case GET_ME: {
            request.get(`/auth/me`)
                .then(response => {
                    if (process.env.NODE_ENV === 'production') {
                        if (response.data) {
                            Sentry.setUser({
                                id: response.data.id,
                                username: `${response.data.fullName} (${response.data.personnel.company.name})`,
                                ip_address: "{{auto}}",
                                email: response.data.email,
                            })
                        }
                    }
                    dispatch(setUser(response.data))
                })
            break;
        }
        default:
            break;
    }
};
