import React, { useMemo, useState } from 'react';
import { Modal, ModalBody, FormGroup, Label, Input, Row, Button, Spinner } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { translate, t } from 'react-switch-lang';
import request from '../../utils/request';
import { toast } from 'react-toastify';

function ModalChangePass({ isOpen, toggle, propsData }) {
    const [loading, setLoading] = useState(false);
    const validationFormSchema = useMemo(() => {
        return Yup.object().shape({
            currentPass: Yup.string().required().label(t("passwordlama")),
            newPass: Yup.string().required()
            .test('sameWithCurrent', t('isiansama'), function(value){
                return value !== this.parent.currentPass
            })
            .test('mustEight', t('isiantdkkurang8'), function(value){
                return value.length >= 8
            })
            .label(t("passwordbaru")),
            confirmPass: Yup.string().required()
            .test('isSame', t('isianhrssama'), function(value){
                return value === this.parent.newPass
            })
            .label(t("konfirmasipasswordbaru"))
        })
    } ,[])

    const { values, touched, errors, isSubmitting, ...formik } = useFormik({
        initialValues: {
            currentPass:'',
            newPass:'',
            confirmPass: ''
        },
        validationSchema: validationFormSchema,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true);
            setLoading(true);
            let dataObject = {
                current: values.currentPass,
                new: values.newPass,
            };
            request.post('auth/changepassword', dataObject)
                .then(() => {
                    toast.success(t('Berhasil Mengganti Password'));
                    toggle();
                    propsData.logout();
                })
                .catch((err) => {
                    if (err.response?.status === 422) {
                        toast.error(t('Password yang anda masukkan tidak sesuai'))
                        return;
                    }
                })
                .finally(() => {
                    setLoading(false);
                    setSubmitting(false);
                })
        }
    })
    const changeCurrentPass = (e) => {
        const {value} = e.target;
        formik.setFieldValue('currentPass', value)
        // formik.setFieldTouched('currentPass', true)
    }
    const changeNewPass = (e) => {
        const {value} = e.target;
        formik.setFieldValue('newPass', value)
        // formik.setFieldTouched('newPass', true)
    }
    const changeConfirmPass = (e) => {
        const {value} = e.target;
        formik.setFieldValue('confirmPass', value)
        // formik.setFieldTouched('confirmPass', true)
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalBody>
                <h5 className="content-sub-title mb-4">{t("gantipassword")}</h5>
                <Row>
                    <div className="col-md-12">
                        <FormGroup>
                            <Label htmlFor="current" className="input-label">
                                {t("passwordlama")}
                            </Label>
                            <Input
                                type="password"
                                name="current"
                                id="current"
                                value={values.currentPass}
                                onChange={changeCurrentPass}
                                onBlur={formik.handleBlur}
                            />
                            {touched.currentPass && errors.currentPass && <small className="text-danger">{errors.currentPass}</small>}
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="new" className="input-label">
                                {t("passwordbaru")}
                            </Label>
                            <Input
                                type="password"
                                name="new"
                                id="new"
                                onChange={changeNewPass}
                                onBlur={formik.handleBlur}
                                value={values.newPass}
                            />
                            {touched.newPass && errors.newPass && <small className="text-danger">{errors.newPass}</small>}
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="confirm" className="input-label">
                                {t("konfirmasipasswordbaru")}
                            </Label>
                            <Input
                                type="password"
                                name="confirm"
                                id="confirm"
                                onChange={changeConfirmPass}
                                onBlur={formik.handleBlur}
                                value={values.confirmPass}
                            />
                            {touched.confirmPass && errors.confirmPass && <small className="text-danger">{errors.confirmPass}</small>}
                        </FormGroup>
                    </div>
                </Row>
                <Row>
                    <div className="col-8 d-flex justify-content-end"></div>
                    <div className="col-4 d-flex justify-content-end">
                        <Button
                            className="mr-2"
                            color="white"
                            onClick={toggle}
                            disabled={loading}
                        >
                            {t("batal")}
                        </Button>
                        <Button
                            type="submit"
                            color="netis-color"
                            onClick={formik.handleSubmit}
                            disabled={loading}
                        >
                            {loading ? <><Spinner color="light" size="sm" /> Loading </> : t("simpan")}
                        </Button>
                    </div>
                </Row>
            </ModalBody>
        </Modal>
    )
}

export default translate(ModalChangePass);