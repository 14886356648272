import { API_REQUEST, apiError, apiSuccess } from "../actions/api";
import { setLoader } from "../actions/ui";
import { toast } from 'react-toastify';
import request from "../utils/request";
import { t } from "react-switch-lang";
export const apiMiddleware = ({ dispatch }) => next => action => {
    next(action);

    if (action.type === API_REQUEST) {
        dispatch(setLoader(true));
        const { url, data } = action.meta;
        request.post(url, data)
            .then(({ data }) => dispatch(apiSuccess({ response: data })))
            .catch(error => {
                dispatch(apiError({ error }));
                toast.error(t('Login Error. Email / Password Salah'), { autoClose: 3000 });

            }).finally(() => {
                dispatch(setLoader(false))
            });
    }
};
