import React, { Fragment, useState, useEffect, useRef } from 'react'
import Webcam from 'react-webcam';
import Colors from '../../../utils/Colors';
import CaptureLoading from '../AttendanceUser/components/CaptureLoading';
import { Modal, ModalBody, Row, Button } from 'reactstrap';
import { translate, t } from 'react-switch-lang';

function CustomWebcam({ onCaptured, trackLocation = true, onUserMedia, ...props }, ref) {

    const [loading, setLoading] = useState(false);
    const [position, setPosition] = useState({ latitude: null, longitude: null });
    const [isSupport, setSupport] = useState({ camera: null, location: null });
    const [isAllowed, setAllowed] = useState({ camera: null, location: null });
    const [result, setResult] = useState({
        isSuccess: null,
        title: null,
        message: null,
        onDone: null,
        previewImage: null,
    });
    const [dataPhoto, setDataPhoto] = useState(null);
    const webcamRef = useRef(ref);

    useEffect(() => {
        navigator.getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia || navigator.oGetUserMedia;
        const supports = { camera: null, location: null }

        if (! (navigator.getUserMedia || navigator.mediaDevices.getUserMedia)) {
            supports.camera = false
        }

        if (trackLocation) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        setAllowed((allows) => ({...allows, location: true }))
                        setPosition({latitude: position.coords.latitude, longitude: position.coords.longitude});
                    },
                    () => setAllowed((allows) => ({...allows, location: false }))
                );
            } else {
                supports.location = false;
            }
        }
        setSupport(supports);

    }, [trackLocation]);

    function capture() {
        const imageUri = webcamRef.current.getScreenshot();
        setDataPhoto(imageUri);
        setLoading(true);
        const reset = () => {
            setResult({isSuccess: null, title: null, message: null, onDone: null, previewImage: null})
            setDataPhoto(null);
        };
        const setError = (params) => setResult((res) => ({...res, ...params, isSuccess: false, title: t('gagal'), onDone: () => reset()}))
        onCaptured({ imageUri: imageUri, position },{ setLoading, setResult, setError, reset })
    }

    return (
        <div>
            <div className="margin-30 d-flex justify-content-center align-items-center" style={{ height: '100vh', width: '100vw' }}>
                {
                    // Show Browser/Device Not Support.
                    isSupport.camera === false || isSupport.location === false ?
                    <div className="text-center">
                        <i className="fa fa-2x fa-warning"></i><br />
                        <h2 className="h5 text-danger">{t(`Maaf, browser ini tidak mendukung fitur kamera${trackLocation ? 'atau lokasi' : ''}`)}</h2>
                        <h2 className="h6 text-netis-primary">{t('Silahkan menggunakan browser atau device yang mendukung')}</h2>
                    </div> :

                    // Show Camera
                    <Fragment>
                        {loading && <CaptureLoading title="Loading" visible={true} />}
                        {result?.isSuccess !== null && 
                            <AfirmationModal 
                                visible={true}
                                title={result?.title || 'Sukses'}
                                text={result?.message}
                                onPressButton={result?.onDone}
                                titleButton={t('Oke')}
                                titleColor={Colors.white}
                                bgColorButton={Colors.primaryColor}
                                image={result?.previewImage}
                            />
                        }
                        {dataPhoto ? <img src={dataPhoto} height="100%" style={{ border: 'solid 5px #63c2de', boxSizing: 'content-box'}} alt="screenshot" /> : 
                        <Webcam
                            audio={false}
                            screenshotFormat="image/jpeg"
                            forceScreenshotSourceSize={true}
                            mirrored={true}
                            style={{ height: '100%', width: '100vw' }}
                            videoConstraints={{ facingMode: 'user' }}
                            {...props}
                            ref={webcamRef}
                            onUserMedia={(mediaStream) => {
                                setAllowed((allows) => ({...allows, camera: true }))
                                if (typeof onUserMedia === 'function') {
                                    onUserMedia(mediaStream, setAllowed);
                                }
                            }}
                            onUserMediaError={() => setAllowed((allows) => ({...allows, camera: false }))}
                        />}
                        
                        {isAllowed.camera && ((trackLocation && isAllowed.location) || !trackLocation) ?
                            <button className="button-capture" onClick={capture} />
                            :
                            <div className="border-warning" style={{ zIndex: 1000, position: 'absolute', left: '1rem', right: '1rem', marginLeft: 'auto', marginRight: 'auto', textAlign: 'center', bottom: 30, padding: '2rem', background: '#fff', borderRadius: '50rem', borderWidth: 5, borderStyle: 'solid'}}>
                                {!(isAllowed.camera && isAllowed.location) && 
                                <h6 className="mb-0">
                                    {t('Anda perlu mengaktifkan kamera')}&nbsp;
                                    {trackLocation ? t('dan lokasi') : ''}
                                    {t('untuk melanjutkan')}
                                </h6>}
                            </div>
                        }
                    </Fragment>
                }
            </div>
        </div>
    )
}

function AfirmationModal(props) {
    return (
        <Modal isOpen={props.visible} className="top-50" autoFocus={true}>
            <ModalBody>
                <Row>
                    <div className="col-12 text-center">
                        <h2 className="title-upgrade" style={{ color: '#93aad6' }}>{props.title}</h2>
                    </div>
                    {props.image && <div className="col-12 text-center mb-3">
                        <img src={props.image} alt="screenshot" width="100%" />
                    </div>}
                    <div className="col-12 text-center">
                        <p>{props.text}</p>
                    </div>
                    <div className="col-12 text-center">
                        <Row>
                            <div className="col-12 text-center">
                                <Button type="submit" className="btn btn-info" style={{ color: props.titleColor }} onClick={props.onPressButton}>
                                    {props.titleButton}
                                </Button>
                            </div>
                        </Row>
                    </div>
                </Row>
            </ModalBody>
        </Modal>
    )
}

export default translate(React.forwardRef(CustomWebcam));
