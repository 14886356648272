import React, { Component } from 'react';

class About extends Component {
    render() {
        return (
            <section className="about" id="about">
                <div className="container py-5">
                    <h1 className="text-center">HRIS by Widya Skilloka</h1>
                    <hr className="hr-main" />
                    <div className="about-image text-center">
                        <img src={require('../../assets/img/landing-page/about.png')} alt="about-img" />
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-10" style={{ lineHeight: 1.7 }}>
                            <p>
                                HRIS adalah platform digital yang akan mempermudah manajemen sumber daya manusia di perusahaan anda. 
                                Produk HRIS Widya Skilloka akan membantu dalam manajemen kehadiran karyawan, memantau lokasi kerja, dan membantu memudahkan pengurusan administrasi mengenai reimburse serta penghitungan cuti karyawan. 
                                Dengan dukungan teknologi Face Recognition, tentunya akan menjadikan aplikasi HRIS Widya Skilloka hadir lebih akurat. 
                                HRIS kami hadir sebagai HR Business Partner yang akan membantu perencanaan strategis pengembangan sumber daya manusia mulai dari proses rekruitmen, asesmen, experimental training hingga konsultasi.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default About;
