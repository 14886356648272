import React from "react";
import { t, subscribe } from "react-switch-lang";
import { lazyComponent as lazy } from "../../components/lazyComponent";

var cachedRoutes = {};
subscribe(() => {
  cachedRoutes = {};
});

// route object
// {url, component, privileges, menu: { name, icon }}
export default (user, props) => {
  if (cachedRoutes[user.session_id]) {
    return cachedRoutes[user.session_id];
  }
  const linkToAikrut = () => {
    window.open(
      `${process.env.REACT_APP_AIKRUT_URL}/login/force?code=${user.encrypted}&id=${user.id}&name=${user.fullName}`
    );
    window.history.back();
    return false;
  };

  const routes = [];

  routes.push({
    url: "/dashboard",
    component: lazy(() => import("../../views/Menu/Dashboard/Dashboard")),
    menu: {
      name: "Dashboard",
      icon: "icon-home",
    },
  });

  routes.push({
    url: "/notification",
    component: lazy(() =>
      import("../../views/Menu/Notification/AdminNotification")
    ),
    menu: {
      name: t("Notifikasi"),
      icon: "icon-bell",
    },
    oneOfPrivileges: [
      "browse-reimburse",
      "read-reimburse",
      "browse-holiday",
      "read-holiday",
      "browse-overtime",
      "read-overtime",
    ],
  });

  routes.push({
    url: "/company",
    exact: true,
    component: lazy(() => import("../../views/Menu/Company/CompanyMenu")),
    oneOfPrivileges: [
      "read-company-profile",
      "read-company-document",
      "read-company-bank",
      "read-company-location",
      "read-company-structure",
      "read-company-holiday",
      "read-company-overtime",
      "read-company-reimburse",
      "read-company-assessment",
      "read-company-role",
    ],
    menu: {
      name: t("perusahaan"),
      icon: "icon-note",
    },
  });

  routes.push({
    url: "/company/pengaturan-dasar",
    exact: true,
    component: lazy(() =>
      import("../../views/Menu/Company/BasicSetting/PengaturanDasar")
    ),
    privileges: [
      "read-company-profile",
      "read-company-document",
      "read-company-bank",
      "read-company-location",
    ],
  });

  routes.push({
    url: "/company/pengaturan-jabatan",
    exact: true,
    component: lazy(() =>
      import("../../views/Menu/Company/PositionSetting/PengaturanJabatan")
    ),
    privileges: ["read-company-structure"],
  });

  routes.push({
    url: "/company/pengaturan-role",
    exact: true,
    component: lazy(() =>
      import("../../views/Menu/Company/RoleSetting/RoleSetting")
    ),
    privileges: ["read-company-role"],
  });
  routes.push({
    url: "/company/pengaturan-role/create",
    exact: true,
    component: lazy(() =>
      import("../../views/Menu/Company/RoleSetting/RoleCreate")
    ),
    privileges: ["edit-company-role"],
  });
  routes.push({
    url: "/company/pengaturan-role/:id",
    exact: true,
    component: lazy(() =>
      import("../../views/Menu/Company/RoleSetting/RoleEdit")
    ),
    privileges: ["edit-company-role"],
  });

  routes.push({
    url: "/company/pengaturan-profil-perusahaan",
    exact: true,
    component: lazy(() =>
      import("../../views/Menu/Company/CompanyProfile/CompanyProfile")
    ),
    privileges: ["read-company-profile"],
  });

  routes.push({
    url: "/company/pengaturan-cuti-perusahaan",
    exact: true,
    component: lazy(() =>
      import("../../views/Menu/Company/CompanyCuti/SettingCuti")
    ),
    privileges: ["read-company-holiday"],
  });

  routes.push({
    url: "/company/pengaturan-cuti-perusahaan/master",
    exact: true,
    component: lazy(() =>
      import("../../views/Menu/Company/CompanyCuti/SettingCuti")
    ),
    privileges: ["read-company-holiday"],
  });

  routes.push({
    url: "/company/general-setting",
    exact: true,
    component: lazy(() =>
      import("../../views/Menu/Company/CompanyGeneralSetting")
    ),
    // privileges: ["read-company-overtime"],
  });

  routes.push({
    url: "/company/master-setting",
    exact: true,
    component: lazy(() =>
      import("../../views/Menu/Company/MasterSetting/MasterWrapper")
    ),
  });

  // Pengaturan lembur & Reimburse (di bawah) digabung jadi satu jadi pengaturan umum (di atas)

  // routes.push({
  //   url: "/company/pengaturan-lembur-perusahaan",
  //   exact: true,
  //   component: lazy(() =>
  //     import(
  //       "../../views/Menu/Company/OvertimePolicySetting/OvertimePolicySetting"
  //     )
  //   ),
  //   privileges: ["read-company-overtime"],
  // });

  // routes.push({
  //   url: "/company/pengaturan-reimburse-perusahaan",
  //   exact: true,
  //   component: lazy(() =>
  //     import(
  //       "../../views/Menu/Company/ReimburseSetting/ReimburseSetting"
  //     )
  //   ),
  //   privileges: ["read-company-reimburse"],
  // });

  routes.push({
    url: "/company/pengaturan-asesmen",
    exact: true,
    component: () => (
      <div className="alert alert-primary text-center">
        <h3>COMING SOON</h3>
      </div>
    ),
    privileges: ["read-company-assessment"],
  });

  // PERSONNEL
  routes.push({
    url: "/personnel",
    component: lazy(() => import("../../views/Menu/Personnel/PersonnelMenu")),
    privileges: ["browse-employee-dataActive"],
    exact: true,
    menu: {
      name: t("karyawan"),
      icon: "icon-people",
    },
  });

  routes.push({
    url: "/personnel/working-shift",
    component: lazy(() =>
      import(
        "../../views/Menu/Personnel/PersonnelWorkingShiftv2/PersonnelWorkingShiftWrapper"
      )
    ),
    privileges: ["read-employee-workingShift"],
  });

  routes.push({
    url: "/personnels",
    exact: true,
    component: lazy(() => import("../../views/Menu/Personnel/PersonnelList")),
    privileges: ["browse-employee-dataActive", "browse-employee-dataNonactive"],
  });

  routes.push({
    url: "/personnels/:id",
    component: lazy(() => import("../../views/Menu/Personnel/PersonnelDetail")),
    privileges: ["read-employee"],
  });

  routes.push({
    url: "/attendance",
    component: lazy(() =>
      import("../../views/Menu/Attendance/AttendanceWrapper")
    ),
    exact: true,
    oneOfPrivileges: ["browse-attendance"],
    menu: {
      name: t("absensi"),
      icon: "icon-pencil",
    },
  });

  routes.push({
    url: "/overtimes",
    component: lazy(() => import("../../views/Menu/Overtime/OvertimeWrapper")),
    privileges: ["browse-overtime"],
    menu: {
      name: t("Lembur"),
      icon: "icon-hourglass",
    },
  });

  routes.push({
    url: "/calendar",
    component: lazy(() => import("../../views/Menu/Calendar/CalendarFunc")),
    privileges: ["browse-calendar"],
    menu: {
      name: t("kalender"),
      icon: "icon-calendar",
    },
  });

  routes.push({
    url: "/cuti",
    component: lazy(() => import("../../views/Menu/Cuti/CutiWrapper")),
    privileges: ["browse-holiday"],
    menu: {
      name: t("cuti"),
      icon: "icon-user-unfollow",
    },
  });

  routes.push({
    url: "/reimburse",
    component: lazy(() =>
      import("../../views/Menu/Reimburse/ReimburseWrapper")
    ),
    privileges: ["browse-reimburse"],
    menu: {
      name: t("Reimburse"),
      icon: "icon-wallet",
    },
  });

  routes.push({
    url: "/payroll",
    component: lazy(() => import("../../views/Menu/Payroll/PayrollMenu")),
    exact: true,
    oneOfPrivileges: [
      "read-settings-payroll",
      "read-entry-payroll",
      "read-workinghours-payroll",
      "read-tax-payroll",
      "read-salaryslip-payroll",
    ],
    menu: {
      name: t("Penggajian"),
      icon: "fa fa-money",
    },
  });

  routes.push({
    url: "/payroll/setting",
    component: lazy(() => import("../../views/Menu/Payroll/PayrollSetting")),
    privileges: ["read-settings-payroll"],
    exact: true,
  });

  routes.push({
    url: "/payroll/entry",
    component: lazy(() => import("../../views/Menu/Payroll/PayrollEntry")),
    privileges: ["read-entry-payroll"],
    exact: true,
  });
  routes.push({
    url: "/payroll/entry/input/:id",
    component: lazy(() => import("../../views/Menu/Payroll/PayrollEntryInput")),
    privileges: ["read-entry-payroll"],
    exact: true,
  });
  routes.push({
    url: "/payroll/workinghours",
    component: lazy(() =>
      import("../../views/Menu/Payroll/PayrollWorkingHours")
    ),
    privileges: ["read-workinghours-payroll"],
    exact: true,
  });

  routes.push({
    url: "/payroll/slip",
    component: lazy(() => import("../../views/Menu/Payroll/PayrollSlip")),
    privileges: ["read-salaryslip-payroll"],
    exact: true,
  });

  routes.push({
    url: "/payroll/slip/:id",
    component: lazy(() => import("../../views/Menu/Payroll/PayrollSlipDetail")),
    privileges: ["read-salaryslip-payroll"],
    exact: true,
  });

  routes.push({
    url: "/payroll/tax-count",
    component: lazy(() => import("../../views/Menu/Payroll/PayrollTax")),
    privileges: ["read-tax-payroll"],
    exact: true,
  });

  routes.push({
    url: "/payroll/tax-count/:id",
    component: lazy(() => import("../../views/Menu/Payroll/PayrollTaxDetail")),
    privileges: ["read-tax-payroll"],
    exact: true,
  });

  routes.push({
    url: "/kpi",
    component: lazy(() => import("../../views/Menu/KPI/KPIWrapper")),
    // privileges: [""],
    menu: {
      name: "KPI",
      icon: "fa fa-file-text",
    },
  });

  routes.push({
    url: "/invoice",
    component: lazy(() => import("../../views/Menu/Invoice/InvoiceList")),
    // privileges: [""],
    exact: true,
    menu: {
      name: t("Tagihan"),
      icon: "icon-credit-card",
    },
  });

  routes.push({
    url: "/assessment/results/:id",
    component: lazy(() =>
      import("../../views/Menu/Assesment/AssessmentResult")
    ),
    privileges: ["read-assessment-result"],
  });

  routes.push({
    url: "/payrollv2",
    component: lazy(() => import("../../views/Menu/PayrollV2")),
    // privileges: [""],
    exact: true,
    menu: {
      name: `${t("Penggajian")} V2`,
      icon: "fa fa-money",
    },
  });
  routes.push({
    url: "/payrollv2/setting",
    component: lazy(() => import("../../views/Menu/PayrollV2/PayrollSetting")),
    // privileges: ["read-tax-payroll"],
    exact: true,
  });

  if (user?.personnel?.company?.enableRecruitment) {
    routes.push({
      url: "/recruitment",
      // component: lazy(() =>
      //   import("../../views/Menu/Recruitment/RecruitmentWrapper")
      // ),
      component: linkToAikrut,
      privileges: ["browse-job"],
      menu: {
        name: "Aikrut",
        icon: "icon-user-follow",
      },
    });

    routes.push({
      url: "/tokenhistory",
      component: lazy(() =>
        import("../../views/Menu/Recruitment/TokenHistory")
      ),
      privileges: ["browse-job"],
    });
  }

  cachedRoutes[user.session_id] = routes;
  return routes;
};
