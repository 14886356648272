import React, { Component, Fragment } from "react";
// import { Redirect } from "react-router-dom";
// import { NavLink } from 'react-router-dom';
import { withRouter } from "react-router";
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Col,
  Row,
  Button,
  Modal,
  ModalBody,
  FormGroup,
  Label,
} from "reactstrap";
import PropTypes from "prop-types";
import Select from "react-select";
import { AppNavbarBrand, AppSidebarToggler } from "@coreui/react";
import logo from "../../assets/assets_ari/logo.png";
import icon from "../../assets/img/icon.png";
import { connect } from "react-redux";
import { logout, setUser } from "../../actions/auth";
import { setPanel } from "../../actions/ui";
import Axios from "axios";
import { translate } from "react-switch-lang";
import langUtils from "../../utils/language/index";
import * as moment from "moment";
import { PANEL_ADMIN, PANEL_USER } from "../../constants";
import NotificationDropdown from "./NotificationDropdown";
// import { Link } from "react-router-dom";
import { withPrivileges } from "../../store";
import request from "../../utils/request";
import ModalChangePass from "./ModalChangePass";

// setLanguageCookie()
class DefaultHeader extends Component {

  constructor(props) {
    super(props);

    this.state = {
      user: props.user,
      session: props.token,
      modalData: false,
      currentPass: null,
      confirmPass: null,
      modalLang: false,
      newPass: "",
      newCompany: null,
      oldCompany: null,
      companyList: [],
    };
  }

  changeLanguage = (id) => (e) => {
    e.preventDefault();
    console.log(id);
    this.handleSetLanguage(id);
    this.setState({
      modalLang: !this.state.modalLang,
    });
    window.location.reload();
  };
  changePass = () => {
    this.setState({
      modalData: !this.state.modalData,
    });
  };
  modalChangeLang = () => {
    this.setState({
      modalLang: !this.state.modalLang,
    });
  };
  changeAdmin = async () => {
    await this.props.setPanel("2");
    this.props.history.push("/dashboard");
  };
  changeUser = async () => {
    await this.props.setPanel("3");
    this.props.history.push("/dashboard");

    // window.location.reload();
    // window.location.replace("/home");
  };
  changeProfile = () => {
    const { history } = this.props;
    history.push("/profile");
    // window.location.replace("/profile/user");
  };
  handleChangeCurrent = (e) => {
    this.setState({
      currentPass: e.target.value,
    });
  };
  handleChangeConfirm = (e) => {
    this.setState({
      confirmPass: e.target.value,
    });
  };
  handleChangeNew = (e) => {
    this.setState({
      newPass: e.target.value,
    });
  };
  handleChangeLang = (e) => {
    this.setState({
      modalLang: e.target.value,
    });
  };

  toggleChangeCompany = async () => {
    this.setState({
      modalChangeCompany: !this.state.modalChangeCompany,
      newCompany: this.state.oldCompany,
    });

    if (this.state.companyList.length === 0)
      Axios.get(process.env.REACT_APP_DOMAIN + "/api/auth/change-company", {
        headers: { Authorization: `Bearer ${this.state.session}` },
      })
        .then((res) => {
          let oldCompany = {};
          const companyList = [];
          res.data.data.data.map((data) => {
            let tmp = {};
            tmp.value = data.id;
            tmp.label = data.name;
            companyList.push(tmp);

            if (data.id === this.props.user.personnel.company.id)
              oldCompany = tmp;
            return data;
          });
          this.setState({ companyList, oldCompany, newCompany: oldCompany });
        })
        .catch((error) => console.log(error));
  };
  handleChangeCompany = (value) => {
    this.setState({
      newCompany: value,
    });
  };
  submitChangeCompany = () => {
    this.setState({ modalChangeCompany: false });

    Axios.post(
      process.env.REACT_APP_DOMAIN + "/api/auth/change-company",
      { companyId: this.state.newCompany.value },
      { headers: { Authorization: `Bearer ${this.state.session}` } }
    )
      .then((res) => {
        const newUser = { ...this.props.user };
        newUser.personnel.company.id = this.state.newCompany.value;
        newUser.personnel.company.name = this.state.newCompany.label;
        setUser(newUser);

        window.location.href = "/home";
      })
      .catch((error) => console.log(error));
  };
  onSelectFlag = (countryCode) => {
    this.handleSetLanguage(countryCode);
    moment.locale(countryCode.toLowerCase());
    let lang = new FormData();
    lang.append('language', countryCode.toLowerCase());
    request.post('auth/language', lang)
  };
  handleSetLanguage = (key) => {
    langUtils.setLanguage(key);
  };

  onAvatarError(e) {
    const img = e.target;
    img.onerror = null;
    img.src = "/assets/img/avatars/avatar.png";
    img.style.border = null;
  }

  getGreetings() {
    const { t } = this.props;
    const today = new Date();
    const currentHour = today.getHours();

    if (currentHour < 12) {
      return t('selamat pagi');
    } else if (currentHour < 15) {
      return t('selamat siang');
    } else if (currentHour < 19) {
      return t('selamat sore');
    } else {
      return t('selamat malam');
    }
  }

  render() {
    // eslint-disable-next-line
    const { t, user, panelMenu: menu, privileges, can, canAny } = this.props;

    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand
          style={{
            position: "initial",
            top: "unset",
            left: "unset",
            marginLeft: 0,
          }}
          full={{ src: logo, width: 100, alt: "Widya Skilloka Logo" }}
          minimized={{ src: icon, width: 30, alt: "Widya Skilloka Icon" }}
        />

        <Nav navbar>
          {menu === PANEL_USER &&
            <>
              {/* sementara buat change language */}
              <UncontrolledDropdown nav direction="down">
                <DropdownToggle nav>{langUtils.getLanguage()}</DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem header>{t("Pilih Bahasa")}</DropdownItem>
                  <DropdownItem onClick={() => this.onSelectFlag("ID")}>
                    Bahasa Indonesia (ID)
                  </DropdownItem>
                  <DropdownItem onClick={() => this.onSelectFlag("EN")}>
                    English (US)
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NotificationDropdown />
            </>
          }

          {menu === PANEL_ADMIN && <UncontrolledDropdown nav direction="down">
            <DropdownToggle nav>{langUtils.getLanguage()}</DropdownToggle>
            <DropdownMenu right>
              <DropdownItem header>{t("Pilih Bahasa")}</DropdownItem>
              <DropdownItem onClick={() => this.onSelectFlag("ID")}>
                Bahasa Indonesia (ID)
              </DropdownItem>
              <DropdownItem onClick={() => this.onSelectFlag("EN")}>
                English (US)
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>}

          <UncontrolledDropdown className="dropdown-profile" nav direction="down">
            <DropdownToggle nav>
              {user.avatar === null ? (
                <div
                  className="round-100 ml-auto"
                  style={{
                    backgroundImage: `url(${"../../assets/img/avatars/avatar.png"})`,
                  }}
                ></div>
              ) : (
                  <div
                    className="round-100 ml-auto"
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_DOMAIN + "" + user.avatar
                        }), url(${"../../assets/img/avatars/avatar.png"})`,
                    }}
                  ></div>
                )}
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem tag="div" className="dropdown-profile-header text-capitalize px-3" header>
                <div style={{ textOverflow: 'ellipsis', width: 200, overflowX: 'hidden' }}>
                  <strong>{user.fullName}</strong>
                </div>
                <div style={{ textOverflow: 'ellipsis', width: 200, overflowX: 'hidden' }}>
                  {user.jabatan}
                </div>
              </DropdownItem>
              {privileges.length > 0 &&
                menu === "3" ? (
                  <Fragment>
                    <DropdownItem onClick={this.changeAdmin}>
                      <i className="fa fa-user"></i>{t('Panel Admin')}
                    </DropdownItem>
                  </Fragment>
                ) : privileges.length > 0 && menu === "2" ? (
                  <DropdownItem onClick={this.changeUser}>
                    <i className="fa fa-user"></i>{t('Panel User')}
                  </DropdownItem>
                ) : null}
              {menu === "3" &&
                < DropdownItem onClick={this.changeProfile}>
                  <i className="fa fa-user-circle-o"></i>{t('Profil')}
                </DropdownItem>
              }
              {can("change-company") &&
                menu === "2" &&
                user.hasSubsidiary === true ? (
                  <DropdownItem onClick={this.toggleChangeCompany}>
                    <i className="fa fa-building-o"></i>
                    {t("lihatperusahaan")}
                  </DropdownItem>
                ) : null}
              {/* {(user?.personnel?.company?.enableRecruitment &&
                canAny(["browse-job", "add-job", "read-job",
                  "edit-job", "browse-job-applicant",
                  "read-job-applicant", "verify-job-applicant", "delete-job"
                ])) &&
                <DropdownItem tag={Link} to="/invoices">
                  <i className="fa fa-list-alt"></i>
                  {t('Daftar Pesanan')}
                </DropdownItem>
              } */}
              <DropdownItem onClick={this.changePass}>
                <i className="fa fa-key"></i>
                {t("gantipassword")}
              </DropdownItem>
              <DropdownItem onClick={this.props.logout}>
                <i className="fa fa-lock"></i>
                {t("keluar")}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        {/*Change Lang*/}
        <Modal isOpen={this.state.modalLang} toggle={this.modalChangeLang}>
          <ModalBody>
            <h5 className="content-sub-title mb-4"> {t("gantibahasa")}</h5>

            <Row>
              <div className="col-md-12">
                <FormGroup>
                  <Row>
                    <Col sm="12" className="text-center">
                      <Label htmlFor="lang" className="input-label ">
                        {t("pilihbahasa")}
                      </Label>
                    </Col>
                    <Col sm="6" className="text-right">
                      <img
                        src={require("../../assets/assets_ari/indonesia.png")}
                        style={{ width: 80, cursor: "pointer" }}
                        alt="indonesia"
                        onClick={this.changeLanguage("ID")}
                      />
                    </Col>
                    <Col sm="6">
                      <img
                        src={require("../../assets/assets_ari/united-states.png")}
                        style={{ width: 80, cursor: "pointer" }}
                        alt="uk"
                        onClick={this.changeLanguage("US")}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </div>
            </Row>
            <Row>
              <div className="col-8 d-flex justify-content-end"></div>
              <div className="col-4 d-flex justify-content-end">
                <Button
                  className="mr-2"
                  color="white"
                  onClick={this.modalChangeLang}
                >
                  {t("batal")}
                </Button>
              </div>
            </Row>
          </ModalBody>
        </Modal>
        {/*Change Pass*/}
        {this.state.modalData &&
          <ModalChangePass
            isOpen={this.state.modalData}
            toggle={this.changePass}
            propsData={this.props}
          />
        }
        {/* Modal Change Company */}
        <Modal
          isOpen={this.state.modalChangeCompany}
          toggle={this.toggleChangeCompany}
        >
          <ModalBody>
            <h5 className="content-sub-title mb-4">
              {t("seesubsidarycompany")}
            </h5>
            <Row>
              <div className="col-md-12">
                <FormGroup>
                  <Select
                    value={this.state.newCompany}
                    onChange={(value) => this.handleChangeCompany(value)}
                    options={this.state.companyList}
                  />
                </FormGroup>
              </div>
            </Row>
            <Row>
              <div className="col-8 d-flex justify-content-end"></div>
              <div className="col-4 d-flex justify-content-end">
                <Button
                  className="mr-2"
                  color="white"
                  onClick={this.toggleChangeCompany}
                >
                  {t("batal")}
                </Button>
                <Button
                  type="submit"
                  color="netis-color"
                  onClick={this.submitChangeCompany}
                >
                  {t("lihat")}
                </Button>
              </div>
            </Row>
          </ModalBody>
        </Modal>
      </React.Fragment >
    );
  }
}

DefaultHeader.propTypes = {
  t: PropTypes.func.isRequired,
};
const mapStateToProps = ({ menu: panelMenu, user, token }) => ({
  panelMenu,
  user,
  token,
});
export default connect(mapStateToProps, { logout, setPanel, setUser })(
  withPrivileges(withRouter(translate(DefaultHeader)))
);
