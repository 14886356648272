import React, { Component } from 'react';

import LogoWhite from '../../assets/assets_ari/logo_white.png';
import ig from '../../assets/assets_ari/ig.svg';
import fb from '../../assets/assets_ari/fb.svg';
import twt from '../../assets/assets_ari/twt.svg';
import { ReactComponent as MapMarkerOutline } from '../../assets/img/map-marker-outline.svg';
import { ReactComponent as WhatsappOutline } from '../../assets/img/whatsapp-outline.svg';
import { ReactComponent as MailOutline } from '../../assets/img/mail-outline.svg';
import {translate, t} from 'react-switch-lang';
// import { Link } from 'react-router-dom';

class Footer extends Component {
    render() {
        // const services = [
        //     { image: 'psikotes', title: 'Psikotes & Asesmen', desc: 'Layanan ini digunakan untuk memprediksi perilaku melalui sejumlah simulasi untuk mengukur kompetensi seseorang dalam menangani tanggung jawab sehingga dapat ditemukan area kekuatan maupun kompetensi yangperlu dikembangkan oleh individu tersebut.' },
        //     { image: 'recruitment', title: 'Recruitment Services', desc: 'Layanan untuk staff search / mass recruitment, yaitu pencarian kandidat yang kompeten untuk memenuhi kebutuhan perusahaan. Lingkup kerja Recruitment Service dimulai dari mencari kandidat, menyeleksi, sampai klien memilih satu orang untuk mengisi satu posisi.' },
        //     { image: 'organizational', title: 'Organizational Development', desc: 'Organizational Development (OD) adalah suatu kerangka kerja yang disusun untuk pengembangan perusahaan melalui analisis visi, misi, tata nilai organisasi, sistem manajemen, struktur organisasi, analisa tanggung jawab, dan indikator keberhasilan perusahaan dengan hasil kinerja setiap karyawan dan pencapaian perusahaan.' },
        //     { image: 'experimental', title: 'Experimental Training', desc: 'Pelatihan merupakan salah satu intervensi yang bisa diberikan untuk meningkatkan pengetahuan, keterampilan, dan mengubah perilaku. Kami menyesuaikan layanan pelatihan dengan kondisi perusahaan berdasarkan analisis kebutuhan pelatihan.' },
        //     { image: 'konseling', title: 'Konseling', desc: 'Layanan konseling intensif yang dilakukan secara personal untuk menggali situasi dan permasalahan, potensi dan tantangan, serta komitmen dan motivasi setiap individu dalam berkarir di perusahaan' },
        //     { image: 'coach', title: 'Coach & Mentoring', desc: 'Coaching & Mentoring adalah layanan pendampingan oleh tenaga profesional untuk membantu mengatasi masalah yang berkaitan dengan kinerja, produktivitas dan wawasan seputar dunia kerja' },
        // ];
        return (
            <footer>
                <div className="container">
                    <img src={LogoWhite} alt="widya_skilloka" className="logo_white img-fluid" />
                    <hr className="footer-divider" />
                    <div className="footer-content d-flex justify-content-between flex-column-reverse flex-md-row">
                        <div className="footer-left p-0 mt-4 mt-md-0">
                            <hr className="footer-divider d-block d-md-none" />
                            <div className="d-flex align-items-start">
                                <div style={{ width: 12, color: '#fff' }} className="mr-3">
                                    <MapMarkerOutline />
                                </div>
                                <p className="mb-1" style={{ flex: '1' }}>Jl. Palagan Tentara Pelajar No.31A KM 7.5<br />
                                Kec. Ngaglik, Kabupaten Sleman, DIY 55581</p>
                            </div>
                            <div className="d-flex align-items-start">
                                <div style={{ width: 12, color: '#fff' }} className="mr-3">
                                    <WhatsappOutline />
                                </div>
                                <p className="mb-1" style={{ flex: '1' }}><a href="https://wa.me/6281226798802" style={{ color: '#dce6ef' }}>+62 812 2679 8802</a></p>
                            </div>
                            <div className="d-flex align-items-start">
                                <div style={{ width: 12, color: '#fff' }} className="mr-3">
                                    <MailOutline />
                                </div>
                                <p className="mb-1" style={{ flex: '1' }}><a href="mailto:info@widyaskilloka.com" style={{ color: '#dce6ef' }}>info@widyaskilloka.com</a></p>
                            </div>

                            <div className="sosmed-lg mt-3">
                                <a href="https://www.instagram.com/widyaskilloka/" target="blank">
                                    <img src={ig} alt="instagram" className="mr-2" />
                                </a>
                                <a href="https://www.facebook.com/" target="blank">
                                    <img src={fb} alt="facebook" className="mr-2" />
                                </a>
                                <a href="https://www.twitter.com/" target="blank">
                                    <img src={twt} alt="twitter" className="" />
                                </a>
                            </div>
                        </div>
                        <div className="footer-right d-flex justify-content-end flex-column flex-md-row-reverse p-0">
                            <ul className="mb-4 mb-md-1">
                                {/* <li><strong>Perusahaan</strong></li> */}
                                <li><a href="/about">Tentang Kami</a></li>
                                {/* <li><a href="/careers">Karir</a></li> */}
                                <li><a href="/contact">Hubungi Kami</a></li>
                                {/* <li><a href="/faq">FAQ</a></li> */}
                                <li><a href="https://widyaskilloka.com/privacy-policy.id.html">{t('Privacy Policy')}</a></li>
                                <li><a href="https://widyaskilloka.com/terms-condition.id.html">{t('Terms and Conditions')}</a></li>
                            </ul>
                            {/* <ul className="mb-4 mb-md-1">
                                <li><strong>Produk</strong></li>
                                <li><Link to="/product-hris">HRIS</Link></li>
                                <li><a href="https://skillana.id">Career Passport</a></li>
                            </ul> */}
                            {/* <ul className="mb-4 mb-md-1">
                                <li><strong>Layanan</strong></li>
                                {services.map((srvc) => {
                                    return (
                                        <li key={srvc.title}><Link to="/services">{srvc.title}</Link></li>
                                    )
                                })}
                            </ul> */}

                        </div>
                    </div>
                    <hr className="hr-footer" />
                    <p className="copyright text-center m-0 p-0">
                        © 2021 PT Widya Indonesia Sejahtera All Rights Reserved
                    </p>
                </div>
            </footer>
        )
    }
}

export default translate(Footer);
