import React from "react";
import NavbarComp from "../../LandingPage/Navbar";
import Footer from "../../LandingPage/Footer";
import PropTypes from "prop-types";
import {
  Responsive,
} from "responsive-react";

import FloatingButton from "../components/FloatingButton";
import FloatingButtonMobile from "../components/FloatingButtonMobile";

function PageLayout(props) {
  return (
    <div className="landing-page">
      <NavbarComp />
      {props.children}

      {props.footer === false ? null : <Footer />}
      <Responsive displayIn={["mobile"]}>
        <FloatingButtonMobile />
      </Responsive>
      <Responsive displayIn={["tablet"]}>
        <FloatingButton />
      </Responsive>
      <Responsive displayIn={["laptop"]}>
        <FloatingButton />
      </Responsive>
    </div>
  );
}
PageLayout.propTypes = {
  children: PropTypes.node,
  footer: PropTypes.bool,
};
PageLayout.defaultTypes = {
  footer: true,
};
export default PageLayout;
