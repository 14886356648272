import React from 'react'
import { Row, Button, Modal, ModalBody } from 'reactstrap';

export default function Notification(props) {
    return (

        <Modal isOpen={props.visible} className="top-50" autoFocus={true}>
            <ModalBody>
                <h3>{props.title}</h3>
                <p>{props.desc}</p>
                <Row>
                    <div className="col-12 d-flex justify-content-end">
                        <Button type="submit" color="netis-color" style={{ width: '67px' }} onClick={props.onClick}>
                            {props.actionTitle}
                        </Button>
                    </div>
                </Row>
            </ModalBody>
        </Modal>

    )
}