import React, { Fragment } from 'react'
import { Row, Modal, ModalBody } from 'reactstrap';

export default function CaptureLoading(props) {
    return (
        <Fragment>
            <Modal isOpen={props.visible} className="top-50">
                <ModalBody>
                    <Row>
                        <div className="col-12 text-center">
                            <h3 className="title-upgrade" style={{ color: '#93aad6' }}>{props.title}</h3>
                        </div>
                    </Row>
                </ModalBody>
            </Modal>
        </Fragment>

    )
}