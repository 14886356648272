import React, { useState, useEffect, Fragment, useRef } from "react"
import { Container, Card, CardBody, CardGroup, Spinner, Alert, Button, Row, Col, Label } from "reactstrap";
import { toast } from 'react-toastify';
import request from "../../../utils/request";
import { Formik, Field, Form } from 'formik'
import { Link } from 'react-router-dom'
import FormikInput from "../../../components/Form/FormikInput";
import Select from 'react-select';
import {
  translate,
} from 'react-switch-lang';
import ReCAPTCHA from 'react-google-recaptcha';
const CLIENT_ID = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;

toast.configure()
export default translate(function Register(props) {
  const { t } = props;
  const recaptchaRef = useRef();

  const [state, setState] = useState({
    companyTypes: null,
    companyType: null,
    userPhone: '',
    companyPhone: '',
  });
  const [success, setSuccess] = useState(false)
  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await request.get(`v1/master/company-types`)
        let companyTypes = [];
        let companyType = {};
        data.data.map(data => {
          companyType = {
            value: data.id,
            label: data.name
          }
          return companyTypes.push(companyType)
        })
        setState({ companyTypes })
      } catch (err) {
        if (err.response) {
          toast.error('Load Data Error. Please Refresh !', { autoClose: 2000 });
        }
      }
    }
    fetchData();
  }, []);
  const handleInputChangePhoneCompany = (e) => {
    e.preventDefault();
    setState({
      ...state,
      companyPhone: e.target.value
    });
  }
  const handleInputChangePhoneUser = (e) => {
    e.preventDefault();
    setState({
      ...state,
      userPhone: e.target.value
    });
  }

  const handleNumberOnly = (evt) => {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)){
          evt.preventDefault()}

        return true;
  }

  const formValues = {
    companyName: '',
    companyDomain: '',
    userFullName: '',
    userNickName: '',
    userEmail: ''
  }
  const formValidate = values => {
    let errors = {}
    if (!values.companyName) {
      errors.companyName = t('isiantdkbolehkosong')
    } else if (!values.companyDomain) {
      errors.companyDomain = t('isiantdkbolehkosong')
    } else if (!values.userFullName) {
      errors.userFullName = t('isiantdkbolehkosong')
    } else if (!values.userNickName) {
      errors.userNickName = t('isiantdkbolehkosong')
    } else if (!values.userEmail) {
      errors.userEmail = t('isiantdkbolehkosong')
    }

    return errors;
  }
  const formSubmit = async (values, { setSubmitting, setErrors }) => {
    if (state.companyType === null) {
      toast.error(t('isiantdkbolehkosong'))
    } else {
      const dataObject = {
        companyName: values.companyName,
        companyDomain: values.companyDomain,
        companyPhone: state.companyPhone,
        companyType: state.companyType.value,
        companyTypeName: state.companyType.label,
        userFullName: values.userFullName,
        userNickName: values.userNickName,
        userPhone: state.userPhone,
        userEmail: values.userEmail,
        captchaValue: values.captchaValue,
      }
      await request.post(`v1/company/create/request`, dataObject)
        .then(res => {
          setSuccess(true)
          toast.success('Register Success', { autoClose: 3000 })
        })
        .catch(err => {
          toast.error('Error Register', { autoClose: 3000 })

        })
        .finally(() => {
          setSubmitting(false)
        })
    }
  }


  return (
    <div className="app flex-row align-items-center background-login" style={{ height: 'auto', padding: 10 }}>
      <Container>
        <Row className="justify-content-center">
          <Col sm={8} md={9}>
            <CardGroup style={{ height: 'auto' }}>
              <Card className="card-login-form" >
                <CardBody style={{ padding: 0 }}>
                  <Formik
                    initialValues={formValues}
                    validate={formValidate}
                    onSubmit={formSubmit}
                    render={({ isSubmitting, values, setFieldValue, errors, setFieldError, touched, setFieldTouched }) => (
                      <Form>
                        <div className="logo text-center">
                          <img src={require("../../../assets/assets_ari/logo.png")} className="logo-widya-skilloka" alt="logo-widya-skilloka" />
                        </div>
                        {success ?
                          <Alert color="info" className="text-center mt-3">
                            <br /><p>{t('Silahkan tunggu Admin melakukan verifikasi pada data Anda')}</p><br />
                          </Alert> :
                          <Fragment>
                            <h5><b>Data {t('perusahaan')}</b></h5>
                            <Row>
                              <Col sm="6">
                                <Field type="text" label={t('namaperusahaan')} name="companyName" id="companyName" component={FormikInput} />
                              </Col>
                              <Col sm="6">
                                <Field type="text" label={t('websiteperusahaan')} name="companyDomain" id="companyDomain" component={FormikInput} />
                              </Col>
                              <Col sm="6">
                                <Label htmlFor="badan-usaha" className="input-label">{t('teleponperusahaan')}</Label>
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text">+</span>
                                  </div>
                                  <input onKeyPress={handleNumberOnly} pattern="[0-9]*" inputMode="numeric" type="text" className="form-control" name="companyPhone" id="companyPhone" placeholder="62" onChange={handleInputChangePhoneCompany} />
                                </div>
                              </Col>
                              <Col sm="6">
                                <Label htmlFor="badan-usaha" className="input-label">{t('bidangperusahaan')}</Label>
                                <Select
                                  value={state.companyType}
                                  onChange={
                                    (value) => {
                                      setState({ ...state, companyType: value })
                                    }
                                  }
                                  options={state.companyTypes}
                                />
                              </Col>
                            </Row>
                            <hr />
                            <h5><b>{t('datadirianda')}</b></h5>
                            <Row>
                              <Col sm="6">
                                <Field type="text" label={t('namalengkap')} name="userFullName" id="userFullName" component={FormikInput} />
                              </Col>
                              <Col sm="6">
                                <Field type="text" label={t('namapanggilan')} name="userNickName" id="userNickName" component={FormikInput} />
                              </Col>
                              <Col sm="6">
                                <Field type="email" label="Email" name="userEmail" id="userEmail" component={FormikInput} />
                              </Col>
                              <Col sm="6">
                                <Label htmlFor="badan-usaha" className="input-label">{t('telepon')}</Label>
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text">+</span>
                                  </div>
                                  <input type="text" onKeyPress={handleNumberOnly} pattern="[0-9]*" inputMode="numeric" className="form-control" name="userPhone" id="userPhone" placeholder="62" onChange={handleInputChangePhoneUser} />
                                </div>
                              </Col>
                            </Row>
                            {/* {console.log(CLIENT_ID)} */}
                            <div className="d-flex mt-3">
                              <div className="mx-auto">{CLIENT_ID !== undefined &&
                                (<ReCAPTCHA ref={recaptchaRef} className="text-center"
                                  sitekey={CLIENT_ID}
                                  onChange={(value) => {
                                    setFieldValue('captchaValue', value);
                                    setFieldTouched('captchaValue', true);
                                    setFieldError('captchaValue', '');
                                  }}
                                  onExpired={() => {
                                    setFieldValue('captchaValue', '')
                                    setFieldError('captchaValue', 'Recaptcha is expired, please check again.')
                                  }} />)
                              }
                                {(errors.captchaValue && touched.captchaValue) && <small className="text-danger">{errors.captchaValue}</small>}
                              </div>
                            </div>
                            <Button type="submit" className="login-submit mt-3" disabled={isSubmitting}>
                              {isSubmitting ? <span><Spinner size="sm" className="mr-2" /> Loading</span> : "Register"}
                            </Button>
                          </Fragment>}
                        <div className="mt-5">
                          <Link to="/login"><i className="mr-2 fa fa-chevron-left"></i> {t('kehalaman')} Login</Link>
                        </div>
                      </Form>
                    )}
                  />
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
})
