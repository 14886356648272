import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Input,
  Row,
  Spinner,
  InputGroup,
  InputGroupAddon,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import ilustration from "../../../assets/assets_ari/5.svg";
import { connect } from "react-redux";
import { login } from "../../../actions/auth";
import { Link } from "react-router-dom";
import { translate } from "react-switch-lang";
import langUtils from "../../../utils/language/index";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      error: null,
      update: "0",
      loading: false,
      showPassword: false,
      isEmailNull: false,
      isPasswordNull: false,
    };
  }

  closeLocalError = () => {
    this.setState({ error: null });
  };
  handleChange = (e) => {
    this.setState({
      update: "0",
      email: `${
        e.target.name === "email" ? e.target.value.trim() : this.state.email
      }`,
      password: `${
        e.target.name === "password" ? e.target.value : this.state.password
      }`,
    });
  };
  async loginProcess() {
    var email = this.state.email;
    var password = this.state.password;
    this.state.email === ""
      ? (this.state.isEmailNull = true)
      : (this.state.isEmailNull = false);
    this.state.password === ""
      ? (this.state.isPasswordNull = true)
      : (this.state.isPasswordNull = false);

    if (!this.state.isEmailNull && !this.state.isPasswordNull) {
      await this.props.login({ email, password });
      this.setState({
        update: "1",
        loading: true,
      });
    }

    if (!this.state.loading) {
      this.setState({
        password: "",
      });
    }
  }
  handleLogin = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    this.loginProcess();
    this.setState({
      loading: false,
    });
  };
  onSelectFlag = (countryCode) => {
    this.handleSetLanguage(countryCode);
  };
  handleSetLanguage = (key) => {
    langUtils.setLanguage(key);
  };
  render() {
    const { t } = this.props;
    const chooseLanguages = {
      ID: "Bahasa Indonesia",
      EN: "English (US)",
    };
    return (
      <div className="app flex-row align-items-center background-login">
        <Container>
          <Row className="justify-content-center">
            <Col md="12">
              <CardGroup>
                <Card className="card-login-form">
                  <CardBody className="flex-column">
                    <form onSubmit={this.handleLogin} className="my-auto">
                      <div className="logo text-center">
                        <img
                          src={require("../../../assets/assets_ari/logo.png")}
                          className="logo-widya-skilloka"
                          alt="logo-widya-skilloka"
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="email" className="input-label">
                          Email / {t("telepon")}
                        </label>
                        <Input
                          type="text"
                          id="email"
                          name="email"
                          placeholder={"Email / " + t("telepon")}
                          autoFocus
                          inputMode="email"
                          autoComplete="username"
                          onChange={this.handleChange}
                        />
                        {this.state.isEmailNull && (
                          <span className="tex-sm text-danger">
                            {`Email ${t("isiantdkbolehkosong")}`}
                          </span>
                        )}
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="email" className="input-label">
                          Password
                        </label>
                        <InputGroup>
                          <Input
                            type={this.state.showPassword ? "text" : "password"}
                            id="password"
                            name="password"
                            placeholder="Password"
                            autoComplete="current-password"
                            value={this.state.password}
                            onChange={this.handleChange}
                          />
                          <InputGroupAddon addonType="append">
                            <Button
                              type="button"
                              tabIndex="-1"
                              onClick={() =>
                                this.setState({
                                  showPassword: !this.state.showPassword,
                                })
                              }
                              // onMouseUp={() =>
                              //   this.setState({ showPassword: false })
                              // }
                              // onMouseOut={() =>
                              //   this.state.showPassword &&
                              //   this.setState({ showPassword: false })
                              // }
                              // onTouchStart={() =>
                              //   this.setState({ showPassword: true })
                              // }
                              // onTouchCancel={() =>
                              //   this.state.showPassword &&
                              //   this.setState({ showPassword: false })
                              // }
                              // onTouchEnd={() =>
                              //   this.state.showPassword &&
                              //   this.setState({ showPassword: false })
                              // }
                              // onMouseDown={() =>
                              //   this.setState({ showPassword: true })
                              // }
                              className="input-group-text input-group-transparent"
                              style={{
                                borderColor: "#b2bbce",
                                boxShadow: "none",
                              }}
                            >
                              {this.state.showPassword ? (
                                <i className="fa fa-eye-slash"></i>
                              ) : (
                                <i className="fa fa-eye"></i>
                              )}
                            </Button>
                          </InputGroupAddon>
                        </InputGroup>
                        {this.state.isPasswordNull && (
                          <span className="tex-sm text-danger">
                            {`Password ${t("isiantdkbolehkosong")}`}
                          </span>
                        )}
                      </div>
                      <div>
                        <Link to="/forgot" style={{ color: "#dc3f46" }}>
                          {t("lupapassword")} ?{" "}
                        </Link>
                      </div>
                      <Button
                        className="login-submit"
                        disabled={this.props.isLoading}
                      >
                        {this.props.isLoading ? (
                          <>
                            <Spinner color="light" size="sm" /> Loading
                          </>
                        ) : (
                          "Login"
                        )}
                      </Button>
                      <div className="mt-3 text-center">
                        <h6>
                          <Link to="/register">{t("daftarperusahaan")}</Link>
                        </h6>
                      </div>
                    </form>
                    <div className="mt-auto text-center">
                      <UncontrolledDropdown>
                        <DropdownToggle size="sm" color="light" caret>
                          {chooseLanguages[langUtils.getLanguage()]}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={() => this.onSelectFlag("ID")}>
                            Bahasa Indonesia
                          </DropdownItem>
                          <DropdownItem onClick={() => this.onSelectFlag("EN")}>
                            English (US)
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </CardBody>
                </Card>
                <Card
                  className="card-login-info d-md-down-none"
                  style={{ backgroundImage: `url(${ilustration})` }}
                >
                  <CardBody className="text-center">
                    <div className="login-info">
                      <img
                        src={require("../../../assets/assets_ari/413.svg")}
                        className="login-img"
                        alt="login-img"
                      />
                      <h2>
                        {t("selamatdatang")} {t("di")} Widya Skilloka
                      </h2>
                      <p>{t("silahkanlogin")}</p>
                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  error: state.error,
  isLoading: state.isLoading,
});

export default connect(mapStateToProps, { login })(translate(Login));
