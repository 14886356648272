import React, { useCallback } from 'react';
import { useSelector } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import { appMiddleware } from "./middlewares/app";
import { apiMiddleware } from "./middlewares/core";
import reducer from './reducer'

const createStoreWithMiddleware = applyMiddleware(
    appMiddleware,
    apiMiddleware
)(createStore);

const store = createStoreWithMiddleware(reducer);


export function useAuthUser() {
    const user = useSelector(state => state.user);
    return user;
}

const cachedPrivileges = {};
export function useUserPrivileges() {
    const privileges = useSelector(state => state.user.privileges)
    const can = useCallback((privilegeName) => {
        if (cachedPrivileges[privilegeName] === undefined) {
            const isAble = privileges.includes(privilegeName);
            cachedPrivileges[privilegeName] = isAble;
        }

        return cachedPrivileges[privilegeName];
    }, [privileges]);

    const canAll = (arr = []) => arr.every(p => can(p))
    const canAny = (arr = []) => arr.some(p => can(p))

    return { privileges, can, canAll, canAny }
}

export function withPrivileges(Component) {
    return (props) => {
        const privilegeToProps = useUserPrivileges();
        return <Component {...props} {...privilegeToProps}/>
    }
}

export function useToken() {
    const token = useSelector(state => state.token);
    return token;
}

export default store;
