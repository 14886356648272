import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  withRouter,
  Route,
} from "react-router-dom";
import "./App.scss";
import { Provider } from "react-redux";

import AuthRoute from "./components/AuthRoute.js";
import OfflineIndicator from "./components/OfflineIndicator.js";

//Route
import LoginPage from "./views/Pages/Login/Login.js";
import ForgotPassword from "./views/Pages/Password/ForgotPassword.js";
import InvitationVerif from "./views/Pages/Invitation/Verif.js";
import ResetPassword from "./views/Pages/Password/ResetPassword.js";
// import Containers from "./containers/DefaultLayout/index.js";
import AttendanceWebcamRegister from "./views/Menu/AttendanceUser/Register.js";
import AttendanceWebcamCheckIn from "./views/Menu/AttendanceUser/CheckIn.js";
import AttendanceWebcamCheckOut from "./views/Menu/AttendanceUser/CheckOut.js";
import TakePalmistry from "./views/Menu/Assesment/palmistry/TakePalmistry.js";
// import AttendanceHistory from './views/Menu/Attendance/History/AttendanceHistory.js';
// import AttendanceRecap from './views/Menu/Attendance/Recapitulation/AttendanceRecap.js';
// import AttendanceRecapDetail from './views/Menu/Attendance/Recapitulation/AttendanceRecapDetail.js';
import RegisterPage from "./views/Pages/Register/Register.js";
import VerifRegisterPage from "./views/Pages/Register/Verif.js";
import store from "./store";
import LandingPage from "./views/LandingPage/LandingPage";
import Layout from "./containers/DefaultLayout/Layout";
import ContactUs from "./views/Pages/ContactUs/ContactUs";
import Services from "./views/Pages/Services/Services";
import AboutPage from "./views/Pages/About/AboutPage";
// import CareerPage from "./views/Pages/Career/CareerPage";
import CareerNotFound from "./views/Pages/Career/CareerNotFound";
import HRISPage from "./views/Pages/Products/HRISPage";
import { Component } from "react";
import FAQPage from "./views/Pages/FAQ/FAQPage";
import OvertimeWebcamStart from "./views/Menu/Overtime/OvertimeWebcamStart";
import OvertimeWebcamEnd from "./views/Menu/Overtime/OvertimeWebcamEnd";
import langUtils from "./utils/language/index";
import { setLocale as setValidationLocale } from "yup";
import validationLocaleID from "./utils/yup/locales/id";
import validationLocaleDefault from "yup/es/locale";
import moment from "moment";
import momentLocales from './utils/language/moment-locales';
import { toast } from 'react-toastify';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fab, fas)
toast.configure();
langUtils.onLanguageChanged((lang) => {
  if (lang.toLowerCase() === "id") {
    setValidationLocale(validationLocaleID);
  } else {
    setValidationLocale(validationLocaleDefault);
  }
});

moment.updateLocale('id', momentLocales.id);
langUtils.onLanguageChanged((lang) => {
  moment.locale(lang.toLowerCase());
});
langUtils.init();

class LandingPageRouteComponent extends Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.path === this.props.location.pathname &&
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return <Route {...this.props} />;
  }
}
const LandingPageRoute = withRouter(LandingPageRouteComponent);

export default function App() {
  return (
    <Provider store={store}>
      <OfflineIndicator />

      <Router>
        <Switch>
          <AuthRoute path="/" exact>
            <LandingPage />
          </AuthRoute>
          <LandingPageRoute path="/contact" exact component={ContactUs} />
          <LandingPageRoute path="/services" exact component={Services} />
          <LandingPageRoute path="/about" exact component={AboutPage} />
          {/* <LandingPageRoute path="/careers" exact component={CareerPage} /> */}
          <LandingPageRoute path="/careers" exact component={CareerNotFound} />
          <LandingPageRoute path="/faq" exact component={FAQPage} />
          <LandingPageRoute path="/product-hris" exact component={HRISPage} />
          <AuthRoute path="/login" type="guest" exact>
            <LoginPage />
          </AuthRoute>
          <AuthRoute path="/register" type="guest" exact>
            <RegisterPage />
          </AuthRoute>

          <AuthRoute path="/assessment/palmistry/take/:hand(left|right)" exact type="private" component={TakePalmistry} />
          <AuthRoute
            path="/overtimes/webcam/start"
            exact
            type="private"
            component={OvertimeWebcamStart}
          />
          <AuthRoute
            path="/overtimes/webcam/end"
            exact
            type="private"
            component={OvertimeWebcamEnd}
          />
          <AuthRoute
            path="/verify/:token"
            type="guest"
            exact
            component={VerifRegisterPage}
          ></AuthRoute>

          <AuthRoute
            path="/attendance/webcam/register"
            type="private"
            exact
            component={AttendanceWebcamRegister}
          />
          <AuthRoute
            path="/attendance/webcam/clockin"
            type="private"
            exact
            component={AttendanceWebcamCheckIn}
          />
          <AuthRoute
            path="/attendance/webcam/clockout"
            type="private"
            exact
            component={AttendanceWebcamCheckOut}
          />

          <AuthRoute
            path="/forgot"
            type="guest"
            exact
            component={ForgotPassword}
          ></AuthRoute>
          <AuthRoute
            path="/invitation/:token"
            type="guest"
            exact
            component={InvitationVerif}
          ></AuthRoute>
          <AuthRoute
            path="/reset/:token"
            type="guest"
            exact
            component={ResetPassword}
          ></AuthRoute>

          <AuthRoute type="private" exact component={Layout} />
        </Switch>
      </Router>
    </Provider>
  );
}
