import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import CustomWebcam from './CustomWebcam'
import captcha from '../../../utils/captcha'
import { dataURIToBlob } from '../../../utils/formatter'
import request from '../../../utils/request'
import { translate } from "react-switch-lang";
import { Modal, ModalBody, FormGroup, Label, Input, Button, Row } from 'reactstrap'
import CaptureLoading from '../AttendanceUser/components/CaptureLoading'

function OvertimeWebcamStart({ t }) {
    const history = useHistory();
    const [ready, setReady] = useState(false);
    const [shouldInputMessage, setShouldInputMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        request.get('v1/overtimes/today').then(res => {
            if (res.status === 204) {
                setShouldInputMessage(true);
            } else if (res.status === 200) {
                const { data: currentOvertime } = res.data;
                if (currentOvertime.startPahId) {
                    setError(t('Anda sudah memulai lembur hari ini'))
                } else {
                    setReady(true);
                }
            }
        }).catch(err => {
            if (err.response?.status === 403) {
                setError(t('Tidak ada permintaan lembur yang sudah disetujui atasan hari ini'));
            }
            return Promise.reject(err);
        })
    }, [t]);

    function showErrorModal() {
        return (<Modal isOpen={true} className="top-50" autoFocus={true}>
        <ModalBody>
            <Row>
                <div className="col-12 text-center">
                    <h2 className="title-upgrade text-warning">{t('Perhatian')}</h2>
                </div>
                <div className="col-12 text-center">
                    <p>{error}</p>
                </div>
                <div className="col-12 text-center">
                    <Row>
                        <div className="col-12 text-center">
                            <Button type="submit" className="btn btn-netis-primary" onClick={() => history.goBack()}>
                                <i className="fa fa-chevron-left mr-2"></i> {t('kembali')}
                            </Button>
                        </div>
                    </Row>
                </div>
            </Row>
        </ModalBody>
    </Modal>)
    }

    if (! ready) {
        return (
            <div className="margin-30 d-flex justify-content-center align-items-center" style={{ height: '100vh', width: '100vw' }}>
                {
                    error ?
                        showErrorModal()
                    :
                    (shouldInputMessage ?
                        <Modal centered={true} isOpen={shouldInputMessage} autoFocus={true}>
                        <ModalBody>
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                setReady(true);
                            }}>
                                <FormGroup>
                                    <Label htmlFor="overtimeMessage" className="input-label">{t('pesan')}</Label>
                                    <Input type="textarea" value={message} onChange={e => setMessage(e.target.value)} name="message" placeholder={t("Tulis alasan lembur anda")} />
                                </FormGroup>
                                <div className="d-flex mt-4 justify-content-end">
                                    <Button type="submit" color="netis-color">{t('selanjutnya')}</Button>
                                </div>
                            </form>
                        </ModalBody>
                    </Modal>
                    :
                    <CaptureLoading title={t('Checking Overtime')} visible={true} />
                )
            }
            </div>
        );
    }

    function onCaptured({ imageUri, position }, { setLoading, setResult, setError, reset }) {

        captcha.get().then(captchaValue => {
            if (!captchaValue) {
                setError({message: 'Sesi anda mungkin telah berakhir, mohon lakukan logout dan login kembali.'});
                setLoading(false);
                return;
            }

            const formData = new FormData();
            formData.append('captcha', captchaValue);
            formData.append('latitude', position.latitude);
            formData.append('longitude', position.longitude);
            formData.append('faceImage', dataURIToBlob(imageUri), 'faceOvertimeIn.jpg');
            if (message.trim()) {
                formData.append('message', message);
            }

            request.post('v1/overtimes/start', formData, { headers: {"Content-Type": "multipart/form-data"}})
                .then(response => {
                    if (response?.status === 200) {
                        const { faceIsMatch, time } = response.data.data;
                        const message = faceIsMatch === '1' ?
                            t('overtimein success with face', { time: time.substr(0, 5) }) : t('overtimein success without face', {time: time.substr(0,5)});
                        setResult({
                            isSuccess: true,
                            faceIsMatch: faceIsMatch === '1',
                            time: time.substr(0,5),
                            previewImage: imageUri,
                            message: message,
                            title: t('success'),
                            onDone: () => history.goBack(),
                        })
                    }
                })
                .catch(err => setError({message: 'Terjadi kesalahan.'}))
                .finally(() => setLoading(false))
        })
    }
    return <CustomWebcam onCaptured={onCaptured} />
}

export default translate(OvertimeWebcamStart)

