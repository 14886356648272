import React, { Component } from 'react';
import Hero from './Hero';
import About from './About';
import Partner from './Partner';
import Features from './Features';
import PageLayout from '../Pages/Layout/PageLayout';

class LandingPage extends Component {
    render() {
        return (
            <PageLayout>
                <div className="landing-page">
                    <Hero />
                    <About />
                    <Partner />
                    <Features />
                </div>
            </PageLayout>
        )
    }
}

export default LandingPage;
