import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import captcha from '../../../utils/captcha'
import { dataURIToBlob } from '../../../utils/formatter'
import request from '../../../utils/request'
import { translate } from "react-switch-lang";
import { Modal, ModalBody, Button, Row } from 'reactstrap'
import CaptureLoading from '../AttendanceUser/components/CaptureLoading'
import CustomWebcam from '../Overtime/CustomWebcam'

function CheckIn({ t }) {
    const history = useHistory();
    const [ready, setReady] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        request.get('v1/attendance/sync_in').then(res => {
            setReady(true);
        }).catch(err => {
            if (err.response?.status === 403) {
                setError(t('presensimasihberjalan'));
                return;
            }

            return Promise.reject(err);
        })
    }, [t]);

    function showErrorModal() {
        return (<Modal isOpen={true} className="top-50" autoFocus={true}>
            <ModalBody>
                <Row>
                    <div className="col-12 text-center">
                        <h2 className="title-upgrade text-warning">{t('Perhatian')}</h2>
                    </div>
                    <div className="col-12 text-center">
                        <p>{error}</p>
                    </div>
                    <div className="col-12 text-center">
                        <Row>
                            <div className="col-12 text-center">
                                <Button type="submit" className="btn btn-netis-primary" onClick={() => history.goBack()}>
                                    <i className="fa fa-chevron-left mr-2"></i> {t('kembali')}
                                </Button>
                            </div>
                        </Row>
                    </div>
                </Row>
            </ModalBody>
        </Modal>)
    }

    if (!ready) {
        return (
            <div className="margin-30 d-flex justify-content-center align-items-center" style={{ height: '100vh', width: '100vw' }}>
                {
                    error ?
                        showErrorModal()
                        :
                        <CaptureLoading title={t('preparing')} visible={true} />
                }
            </div>
        );
    }

    function onCaptured({ imageUri, position }, { setLoading, setResult, setError, reset }) {

        captcha.get().then(captchaValue => {
            if (!captchaValue) {
                setError({ message: 'Sesi anda mungkin telah berakhir, mohon lakukan logout dan login kembali.' });
                setLoading(false);
                return;
            }

            const formData = new FormData();
            formData.append('captcha', captchaValue);
            formData.append('latitude', position.latitude);
            formData.append('longitude', position.longitude);
            formData.append('faceFile', dataURIToBlob(imageUri), 'faceClockIn.jpg');

            request.post('v1/attendance/clockin', formData, { headers: { "Content-Type": "multipart/form-data" } })
                .then(response => {
                    if (response?.status === 200) {
                        const { faceIsMatch, time } = response.data.data;
                        const message = faceIsMatch === '1' ?
                            t('selamatcheckindicatat') + time.substr(0, 5) : t('berhasilabsenmasuk') + time.substr(0, 5) + ' ' + t('tetapiwajahtdksesuai');
                        setResult({
                            isSuccess: true,
                            faceIsMatch: faceIsMatch === '1',
                            time: time.substr(0, 5),
                            previewImage: imageUri,
                            message: message,
                            title: t('success'),
                            onDone: () => history.goBack(),
                        })
                    }
                })
                .catch(err => setError({ message: 'Terjadi kesalahan.' }))
                .finally(() => setLoading(false))
        })
    }
    return <CustomWebcam onCaptured={onCaptured} />
}

export default translate(CheckIn)

