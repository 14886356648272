import React, { useState, useEffect } from "react"
import { Container, Card, CardBody, CardGroup, Row, Col, Spinner } from "reactstrap";
import { toast } from 'react-toastify';
import request from "../../../utils/request";
import {
    translate,
} from 'react-switch-lang';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
toast.configure()
export default translate(function Verif(props) {
    const { t } = props;
    const token = props.match.params.token
    const [isValid, setIsValid] = useState(true);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await request.get(`invitation/${token}`)
                setIsValid(true)
                setLoading(false)
            } catch (err) {
                if (err.response) {
                    setIsValid(false)
                    setLoading(false)
                }
            }
        }
        fetchData();
    }, [token]);

    return (
        <div className="app flex-row align-items-center background-login" style={{ height: 'auto', padding: 10 }}>
            <Container>
                <Row className="justify-content-center">
                    <Col sm={8} md={9}>
                        <CardGroup style={{ height: 'auto' }}>
                            <Card className="card-login-form" >
                                <CardBody style={{ height: '50vh' }}>
                                    {loading ?
                                        <Spinner color="dark" />
                                        :
                                        isValid
                                            ? <div className="text-center">
                                                <FontAwesomeIcon icon="check-circle" color="green" size="10x" className="mb-5" />
                                                <h5>{t('invitationVerif')}</h5>
                                            </div>
                                            : <div className="text-center">
                                                <FontAwesomeIcon icon="times-circle" color="red" size="10x" className="mb-5" />
                                                <h5>{t('invitationVerifNotValid')}</h5>
                                            </div>
                                    }
                                </CardBody>
                            </Card>
                        </CardGroup>
                    </Col>
                </Row>
            </Container>
        </div>
    );
})
