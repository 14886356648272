import React, { Component } from 'react';

class Features extends Component {
    render() {
        return (
            <section id="features" style={{ marginBottom: 70 }}>
                <div className="container py-5">
                    <h2 className="h1 text-center">Fitur-Fitur Kami</h2>
                    <hr className="hr-main" />
                    <div className="row justify-content-around no-gutters" style={{ marginTop: 50 }}>
                        <div className="col-5 col-md-3 mb-4">
                            <FeatureItem 
                                image={require('../../assets/img/illustrations/feature-attendance.png')} 
                                text="Memantau Kehadiran Karyawan"/>
                        </div>
                        <div className="col-5 col-md-3 mb-4">
                            <FeatureItem 
                                image={require('../../assets/img/illustrations/feature-face-recognition.png')} 
                                text="Menggunakan sistem pengenalan wajah"/>
                        </div>
                        <div className="col-5 col-md-3 mb-4">
                            <FeatureItem 
                                image={require('../../assets/img/illustrations/feature-work-location.png')} 
                                text="Memantau lokasi kerja"/>
                        </div>
                        <div className="w-100 d-none d-md-block"></div>
                        <div className="col-5 col-md-3 mb-4">
                            <FeatureItem 
                                image={require('../../assets/img/illustrations/feature-reimbursement.png')} 
                                text="Mempermudah Pengajuan Reimbursement"/>
                        </div>
                        {/* <div className="col-5 col-md-3 mb-4">
                            <FeatureItem 
                                image={require('../../assets/img/illustrations/feature-assesment.png')} 
                                text="Mempermudah asesmen karyawan"/>
                        </div> */}
                        <div className="col-5 col-md-3 mb-4">
                            <FeatureItem 
                                image={require('../../assets/img/illustrations/feature-leave.png')} 
                                text="Mempermudah Pengajuan cuti"/>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

function FeatureItem(props) {
    return (
        <div className="d-flex flex-column align-items-center w-100 h-100 feature-item">
            <img src={props.image} className="mb-4" height="130" alt="illustration"/>
            <h3 style={{ width: '80%', lineHeight: 1.5 }} className="h6 text-center">{props.text}</h3>
        </div>
    );
}

export default Features;
