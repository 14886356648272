import { subscribe, t } from "react-switch-lang";
import { lazyComponent as lazy } from '../../components/lazyComponent';

const buildUserMenu = () => [
    {
        url: "/dashboard",
        component: lazy(() =>
            import("../../views/Menu/DashboardUser/DashboardUser")
        ),
        menu: {
            name: "Dashboard",
            icon: "icon-home",
        },
    },
    {
        url: "/notifications",
        component: lazy(() => import('../../views/Menu/Notification/Notification'))
    },
    {
        url: "/profile",
        component: lazy(() =>
            import("../../views/Menu/Profile/PersonnelDetailUser")
        ),
    },
    {
        url: "/attendance/user",
        component: lazy(() =>
            import("../../views/Menu/AttendanceUser/AttendanceUser")
        ),
        menu: {
            name: t("absensi"),
            icon: "icon-people",
        },
    },
    {
        url: "/cuti",
        component: lazy(() => import("../../views/Menu/Cuti/CutiWrapper")),
        menu: {
            name: t("cuti"),
            icon: "icon-user-unfollow",
        },
    },
    {
        url: "/reimburse",
        component: lazy(() =>
            import("../../views/Menu/Reimburse/ReimburseWrapper")
        ),
        menu: {
            name: "Reimburse",
            icon: "icon-wallet",
        },
    },
    {
        url: "/overtimes",
        component: lazy(() => import("../../views/Menu/Overtime/OvertimeWrapper")),
        menu: {
            name: t("Lembur"),
            icon: "icon-hourglass",
        },
    },
    {
        url: "/payroll-user",
        component: lazy(() => import("../../views/Menu/Payroll/PayrollUser")),
        menu: {
            name: t('Penggajian'),
            icon: 'fa fa-money',
        },
    },
    // {
    //     url: "/assessment",
    //     component: lazy(() => import("../../views/Menu/Assesment/AssesmentMenu")),
    //     exact: true,
    //     menu: {
    //         name: t("Asesmen"),
    //         icon: "icon-puzzle",
    //     },
    // },
    {
        url: "/kpi",
        component: lazy(() => import("../../views/Menu/KPI/KPIWrapper")),
        menu: {
            name: 'KPI',
            icon: 'fa fa-file-text',
        }
    },
    {
        url: "/assessment/mbti-tes",
        component: lazy(() =>
            import("../../views/Menu/Assesment/mbti/AssesmentMbti")
        ),
    },
    {
        url: "/assessment/papikostick-tes",
        component: lazy(() =>
            import("../../views/Menu/Assesment/papikostick/AssesmentPapikostick")
        ),
    },
    {
        url: "/assessment/disc-tes",
        component: lazy(() =>
            import("../../views/Menu/Assesment/disc/AssesmentDisc")
        ),
    },
];

var userCachedRoutes;
const userMenu = () => {
    if (!userCachedRoutes) {
        userCachedRoutes = buildUserMenu();
    }

    return userCachedRoutes;
}

subscribe(() => {
    userCachedRoutes = undefined;
})

export default userMenu;
