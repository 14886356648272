import React, { useState } from 'react';
import Webcam from "react-webcam";
import Notification from "./components/Notification";
import Hint from "./components/Hint";
import { toast } from 'react-toastify';
import { Spinner, Modal } from 'reactstrap';
import { translate } from 'react-switch-lang';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../../actions/auth';
import request from '../../../utils/request';

let imageSrc = [];

const Register = (props) => {
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();

    const { t } = props;
    const [state, setState] = useState({
        isLoading: false,
        isNotify: true,
        isHint: false,
        hintNumber: 1,
        isUploadSuccess: false,
        dataFaceId: null,
        isCapture: false,
        isClickCapture: true,
        isAllowed: false
    });
    const [isSplash, setIsSplash] = useState(false);
    const webcamRef = React.useRef(null);
    // eslint-disable-next-line
    const [imgSrc, setImgSrc] = React.useState(null);



    const showNotification = () => {
        if (state.isNotify) {
            if (imageSrc.length === 0) {
                return (
                    <Notification
                        onClick={() => { setState({ ...state, isNotify: false, isHint: true, hintNumber: 1 }) }}
                        animationType="none"
                        visible={true}
                        title={t('selamatdatang')}
                        desc={t('wajahbelumterdaftar')}
                        actionTitle="Oke"
                    />
                );
            }

            else {
                return (
                    <Notification
                        onClick={() =>
                            setState({ isNotify: false, isLoading: true })
                        }
                        animationType="none"
                        visible={true}
                        title="Success"
                        desc={t('berhasildirekam')}
                        actionTitle="Selesai"
                    />
                );
            }
        }
    };

    const showHint = (e) => {

        if (state.isHint) {
            if (state.hintNumber === 1) {
                return (
                    <Hint
                        onClick={() => setState({ ...state, isNotify: false, hintNumber: 2 })}
                        visible={state.isHint}
                        img="hint1.png"
                        desc={t('hint1')}
                        actionTitle={t('selanjutnya')}
                    />
                );
            }
            if (state.hintNumber === 2) {
                return (
                    <Hint
                        onClick={() => setState({ ...state, isNotify: false, hintNumber: 3 })}
                        backBtn={true}
                        onBack={() => setState({ ...state, isNotify: false, hintNumber: 1 })}
                        visible={state.isHint}
                        img="hint2.png"
                        desc={t('hint2')}
                        actionTitle={t('selanjutnya')}
                    />
                );
            }
            if (state.hintNumber === 3) {
                return (
                    <Hint
                        onClick={() => setState({ ...state, isNotify: false, hintNumber: 4 })}
                        backBtn={true}
                        onBack={() => setState({ ...state, isNotify: false, hintNumber: 2 })}
                        visible={state.isHint}
                        img="hint3.png"
                        desc={t('hint3')}
                        actionTitle={t('selanjutnya')}
                    />
                );
            }
            if (state.hintNumber === 4) {
                return (
                    <Hint
                        onClick={() => setState({ ...state, isNotify: false, hintNumber: 5 })}
                        backBtn={true}
                        onBack={() => setState({ ...state, isNotify: false, hintNumber: 3 })}
                        visible={state.isHint}
                        img="hint4.png"
                        desc={t('hint4')}
                        actionTitle={t('selanjutnya')}
                    />
                );
            }
            if (state.hintNumber === 5) {
                return (
                    <Hint
                        onClick={() => setState({ ...state, isNotify: false, hintNumber: 6 })}
                        backBtn={true}
                        onBack={() => setState({ ...state, isNotify: false, hintNumber: 4 })}
                        visible={state.isHint}
                        img="hint5.png"
                        desc={t('hint5')}
                        actionTitle={t('selanjutnya')}
                    />
                );
            }
            if (state.hintNumber === 6) {
                return (
                    <Hint
                        title="WARNING !!"
                        onClick={() => setState({ ...state, isNotify: false, isHint: false })}
                        backBtn={true}
                        onBack={() => setState({ ...state, isNotify: false, hintNumber: 5 })}
                        animationType="slide"
                        visible={state.isHint}
                        img="hint6.png"
                        desc={t('hint6')}
                        actionTitle="Okey"
                    />
                );
            }

        }
    };

    const uploadNotification = (e) => {

        if (state.isUploadSuccess) {
            return (
                <Notification
                    onClick={() => {
                        return window.location.replace("/home");
                    }}
                    visible={true}
                    title="Success"
                    desc={t('selamatregister')}
                    actionTitle="Oke"
                />
            );
        }
    };
    const DataURIToBlob = (dataURI) => {
        const splitDataURI = dataURI.split(',')
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i)
        }
        return new Blob([ia], { type: mimeString })
    }
    const sendData = async (e) => {

        let formData = new FormData();
        let file;
        imageSrc.forEach((item, i) => {
            file = DataURIToBlob(item)
            formData.append(`image[]`, file, 'face' + i + '.jpg');
            i++
        });

        const url = 'v1/attendance/register';
        await request.post(url, formData)
            .then(({ status: res, data }) => {
                if (res === 200) {
                    setState({
                        isLoading: false,
                        dataFaceId: data.faceId,
                        isUploadSuccess: true
                    });
                    let updatedUser = { ...user };
                    updatedUser['faceId'] = data.faceId;
                    dispatch(setUser(updatedUser));
                } else {
                    toast.error('Failed Register', { autoClose: 3000 })
                }
            })
            .catch(error => {
                toast.error('Failed Register', { autoClose: 3000 })
            });
    };
    const splash = () => {
        if (isSplash) {
            return (
                <Modal isOpen={isSplash} animationType="none" className="splash" fade={false}>

                </Modal>

            );
        }
    };


    const capture = React.useCallback((e) => {
        e.preventDefault();
        setState({ isClickCapture: false });
        let looper = setInterval(function () {
            if (imageSrc.length >= 10) {
                clearInterval(looper);
                sendData();
                setState({ isCapture: false, isNotify: false });
            } else {
                setIsSplash(true);

                setTimeout(() => {
                    setIsSplash(false);
                }, 100);

                imageSrc.push(webcamRef.current.getScreenshot());
                // console.log(imageSrc)
            }
        }, 2500);
        // eslint-disable-next-line
    }, [webcamRef, setImgSrc]);

    const videoConstraints = {
        facingMode: "user"
    };

    return (
        <div>

            {showNotification()}
            {showHint()}
            {uploadNotification()}
            {splash()}
            <div className="margin-30" style={{ height: window.outerHeight }}>
                <div className="text-center flexbox-wrapper">
                    <div className="flexboxi-1 text-center">

                        <Webcam
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            forceScreenshotSourceSize={true}
                            mirrored={true}
                            style={{ height: '100vh', width: '100vw' }}
                            videoConstraints={videoConstraints}

                        />
                        {state.isClickCapture === true ? (
                            <button className="button-capture" onClick={capture} />
                        )
                            :
                            (
                                <div className="bottom-capture">
                                    <Spinner size="sm" className="mr-2" />
                                    <p>
                                        {t('hint6')}

                                    </p>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>


        </div>
    );



};



export default (translate(Register));
