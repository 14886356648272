import React, { Component, Suspense, useMemo, useState } from 'react';
import * as router from 'react-router-dom';
import { AppSidebar, AppSidebarFooter, AppSidebarForm, AppSidebarHeader, AppSidebarNav2 as AppSidebarNav, AppHeader } from '@coreui/react';
import { translate } from 'react-switch-lang';
import DefaultHeader from './DefaultHeader';
import { Container, Modal, ModalBody, Spinner } from 'reactstrap';
import adminMenus from './admin-menu';
import userMenus from './user-menu';
import LoadingAnimation from '../../views/Menu/Personnel/component/atom/LoadingAnimation';
import AuthRoute from '../../components/AuthRoute';
import { connect, useDispatch } from 'react-redux';
import * as firebase from '../../firebaseInit';
import request from '../../utils/request';
import { setPanel } from '../../actions/ui';
// import { logout } from '../../actions/auth';
// import { PANEL_ADMIN } from '../../constants';
import { setLanguage } from 'react-switch-lang';
import useSWR from 'swr';

class SidebarNav extends AppSidebarNav {

    // nav type
    navType(item, idx) {
        return (
            item.title ? this.navTitle(item, idx)
                : item.divider ? this.navDivider(item, idx)
                    : item.label ? this.navLabel(item, idx)
                        : item.children ? this.navDropdown(item, idx)
                            : typeof item.render === 'function' ? item.render(item, idx)
                                : this.navItem(item, idx)
        );
    }
}

class DefaultLayout extends Component {
    componentDidMount() {
        setLanguage(localStorage.getItem('language'))
        firebase.requestNotificationPermission().then(token => {
            request.post('/auth/addtoken', { token, platform: navigator?.userAgent ?? 'web' })
        }).catch((err) => {
            console.log(err.message)
        })
    }

    generateMenus(menu) {
        const { privileges: userPrivileges = [] } = this.props.user;
        // console.log(userPrivileges);
        const checkPrivileges = (routeObj) => {
            if (routeObj.privileges) {
                return routeObj.privileges.every(p => userPrivileges.includes(p));
            }

            if (routeObj.oneOfPrivileges) {
                return routeObj.oneOfPrivileges.some(p => userPrivileges.includes(p));
            }

            return true;
        }

        if (
            // userPrivileges.indexOf('access admin panel') >= 0 &&
            menu === '2'
        ) {
            return adminMenus(this.props.user)
                .filter(routeObj => !!routeObj.menu)
                .filter(checkPrivileges)
                .map(({ menu, url }) => {
                    return { ...menu, url }
                });
        } else {
            return userMenus()
                .filter(routeObj => !!routeObj.menu)
                .filter(checkPrivileges)
                .map(({ menu, url }) => ({ ...menu, url }))
        }
    }

    generateRoutes(menu) {
        if (
            // privileges.indexOf('access admin panel') >= 0 &&
            menu === '2') {
            return adminMenus(this.props.user)
                .map((props, idx) => {
                    if (props.url && (props.redirect || props.component)) {
                        return <AuthRoute key={idx} path={props.url} exact={!!props.exact} component={props.component} {...props} />
                    }
                    return null;
                });
        } else {
            return userMenus()
                .map((props, idx) =>
                    !!props.redirect ?
                        <router.Redirect from={props.url} to={props.redirect} exact={!!props.exact} />
                        :
                        <AuthRoute key={idx} path={props.url} exact={!!props.exact} component={props.component} {...props} />
                );
        }
    }

    render() {
        const menu = this.props.panelMenu;
        // const { user } = this.props;
        const { Switch, Redirect } = router;
        if (localStorage.getItem("menu") === "2" && this.props.user.statusExpired === 'expired') return <ModalExpired />

        return (
            <div className="app">
                <AppHeader fixed><DefaultHeader /></AppHeader>
                <div className="app-body">
                    <AppSidebar fixed display="lg">
                        <AppSidebarHeader />
                        <AppSidebarForm />
                        <Suspense>
                            <SidebarNav navConfig={{ items: this.generateMenus(menu) }} router={router} location={this.props.location} />
                        </Suspense>
                        <AppSidebarFooter />
                    </AppSidebar>

                    <main className="main">
                        <Container fluid>
                            <Suspense fallback={<LoadingAnimation />}>
                                <Switch>
                                    <Redirect exact from="/home" to="/dashboard" />
                                    {this.generateRoutes(menu)}
                                </Switch>
                            </Suspense>
                        </Container>
                    </main>
                </div>
                {localStorage.getItem("menu") === "2" && this.props.user.statusExpired === 'pending' ? <ModalReminder /> : null}
            </div>
        );
    }
}

const ModalExpired = () => {
    const { data: response, error } = useSWR('v1/invoice/expired', { refreshInterval: 10000 })
    const dispatch = useDispatch();
    const loading = !response && !error;
    const invoicesExpired = useMemo(() => {
        if (response) {
            return response.data.data;
        }
        return [];
    }, [response]);
    return (
        <Modal isOpen={true} centered>
            <ModalBody>
                {loading
                    ? <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, background: 'rgba(255,255,255, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Spinner style={{ width: 48, height: 48 }} />
                    </div>
                    : <div>
                        <div className="text-center">Anda tidak bisa menggunakan Fitur Admin sampai tagihan anda terselesaikan. Terima Kasih.</div>
                        <div className="d-flex justify-content-center align-items-center mt-4">
                            <a href={invoicesExpired[0].url} target="_blank" rel="noopener noreferrer" className="btn btn-netis-primary">Bayar Tagihan</a>
                        </div>
                        <div className="text-danger text-center mt-3" style={{ cursor: "pointer" }} onClick={() => dispatch(setPanel(3))}><u>Kembali ke Panel User</u></div>
                    </div>
                }
            </ModalBody>
        </Modal>
    )
}

const ModalReminder = () => {
    const { data: response, error } = useSWR('v1/invoice/expired', { refreshInterval: 10000 })
    const loading = !response && !error;
    const invoicesExpired = useMemo(() => {
        if (response) {
            return response.data.data;
        }
        return [];
    }, [response]);
    const [modal, setModal] = useState(true);

    return (
        <Modal isOpen={modal} centered toggle={() => setModal(false)}>
            <ModalBody>
                {loading
                    ? <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, background: 'rgba(255,255,255, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Spinner style={{ width: 48, height: 48 }} />
                    </div>
                    : <div>
                        <div className="text-center">Silahkan bayar tagihan anda bulan ini, maksimal pembayaran tagihan anda tanggal 20. Terima Kasih.</div>
                        <div className="d-flex justify-content-center align-items-center mt-4">
                            <a href={invoicesExpired[0].url} target="_blank" rel="noopener noreferrer" className="btn btn-netis-primary">Bayar Tagihan</a>
                        </div>
                        <div className="text-danger text-center mt-3" style={{ cursor: "pointer" }} onClick={() => setModal(false)}><u>Tutup</u></div>
                    </div>
                }
            </ModalBody>
        </Modal>
    )
}

const mapStateToProps = (reduxState) => ({ user: reduxState.user, panelMenu: reduxState.menu })

export default connect(mapStateToProps)(translate(DefaultLayout));
