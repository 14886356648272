import React, { Component } from 'react';
import AppStore from '../../assets/assets_ari/appstore.png';
import GooglePlay from '../../assets/assets_ari/playstore.png';

class Hero extends Component {
    render() {
        return (
            <section className="hero hero-image">

                <div className="container">
                    <div className="hero-content mx-auto mx-sm-0">
                        <h1 className="mb-4" style={{ lineHeight: 1.4 }}>Permudah Sistem Presensi<br /> dengan <span style={{ color: '#1472BB' }}>Teknologi Pengenalan Wajah</span></h1>
                        {/* <div className="d-sm-none" style={{ height: '1rem'}}></div> */}
                        <div className="text-left">
                        <p style={{ marginBottom: '0.5rem'}}>Perusahaanmu butuh sistem presensi yang bisa dilakukan dimana aja?</p>
                        <p style={{ marginBottom: '0.5rem'}}>Pengen pengurusan cuti dapat diakses melalui gadget?</p>
                        <p style={{ marginBottom: '0.5rem'}}>Ganti cara presensi dengan metode <strong style={{ fontSize: '120%' }}>#AbsenTanpaRibet</strong></p>
                        </div>
                            <div className="d-flex justify-content-between my-4" style={{width:"80%"}}>
                                <a href="https://apps.apple.com/id/app/widya-skilloka/id1542737341" target="_blank" rel="noopener noreferrer" className="unduh-app mr-md-5">
                                    <img src={AppStore} alt="appstore" className="img-fluid app-img" />
                                </a>
                                <a href='https://play.google.com/store/apps/details?id=com.widyaskilloka.hris' target="_blank" rel="noopener noreferrer"
                                    className="unduh-app">
                                    <img src={GooglePlay} alt="googleplay" className="img-fluid app-img" />
                                </a>
                            </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Hero;
