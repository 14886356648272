import React, { useCallback, useRef } from 'react'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { dataURIToBlob } from '../../../../utils/formatter';
import './palmistry.scss';
import request from '../../../../utils/request';
import CustomWebcam from '../../Overtime/CustomWebcam';
import { useAuthUser } from '../../../../store';
import { setUser } from '../../../../actions/auth';
import { useDispatch } from 'react-redux';

function processImage(base64Image, { flip = false}) {
    return new Promise((resolve, reject) => {
        try {
            const img = new Image();
            img.src = base64Image;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.naturalWidth;
                canvas.height = img.naturalHeight;
                const ctx = canvas.getContext('2d');
                if (flip) {
                    ctx.scale(-1, 1);
                }
                ctx.drawImage(img, 0, 0, img.width * (flip ? -1 : 1), img.height);
                resolve(canvas.toDataURL("image/jpeg"));
            }
        } catch (err) {
            reject(err);
        }
    })    
}

function TakePalmistry() {
    const { hand } = useParams();
    const camRef = useRef();
    const user = useAuthUser();
    const dispatch = useDispatch();
    const history = useHistory();
    const tryCount = useRef(1);

    const takePicture = useCallback((photo) => {
        const dataPhoto = photo ?? camRef.current.getScreenshot();

        return processImage(dataPhoto, { flip: hand === 'right' })
            .then(processedImage => {
                const photoFile = dataURIToBlob(processedImage);
                const formData = new FormData();
                formData.append('file', photoFile, 'palmistry.jpeg');
                formData.append('try', tryCount.current);

                return request.post('v1/assessment/test/palmistry', formData)
                    .then(res => {
                        return Promise.resolve({ dataPhoto, processedImage });
                    })
                    .catch(err => {
                        tryCount.current = tryCount.current + 1;
                        return Promise.reject({...err, dataPhoto, processedImage });
                    })
            })
    }, [hand, camRef]);

    if (user.hasPalmistry) {
        return <Redirect to="/dashboard" />
    }

    return (
        <div className="component-take-palmistry">
            <div className={`hand-background-${hand === 'right' ? 'kanan' : 'kiri'}`}></div>
            <CustomWebcam
                audio={false}
                trackLocation={false}
                screenshotFormat="image/jpeg"
                forceScreenshotSourceSize
                mirrored
                style={{ height: '100%', width: '100vw', zIndex: 1 }}
                videoConstraints={{ facingMode: 'user' }}
                ref={camRef}
                onCaptured={({ imageUri }, funcs) => {
                    takePicture(imageUri).then(() => {
                        funcs.setLoading(false);
                        funcs.setResult({
                            title: 'Terimakasih',
                            message: 'data anda berhasil disimpan.',
                            onDone: () => {
                                dispatch(setUser({...user, hasPalmistry: true }));
                                history.goBack();
                            }
                        });
                    }).catch(err => {
                        funcs.setLoading(false);
                        funcs.setError({
                            message: err?.response?.message ?? 'Maaf deteksi tangan tidak ditemukan',
                        });
                    })
                }}
            />
        </div>
    )
}

export default TakePalmistry
